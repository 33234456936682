/* eslint-disable max-len */
import Link from 'next/link';
import { useState } from 'react';
import { CTA, IconName } from '../types';
import { ButtonIcon } from './ButtonIcon';
import { IconVariant } from '../utils';

import { useScroll, useMotionValueEvent, motion } from 'framer-motion';

export type FloatingButtonProps = {
  cta: CTA;
  right: number;
  bottom: number;
};

export const FloatingButton = ({ bottom, right, cta: CTAS }: FloatingButtonProps) => {

  const { scrollYProgress } = useScroll();
  
  const [isScrolled, setIsScrolled] = useState(false);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    setIsScrolled(latest > 0);
  });

  const ctas = CTAS || [{ href: 'javascript:void(0)', target: '_self'}]

  return <motion.div animate={{opacity: isScrolled ? 1 : 0, translateY: isScrolled ? 0 : 20}} style={{pointerEvents: isScrolled ? 'auto' : 'none'}} className="fixed flex flex-row-reverse z-50 bottom-[20px] right-[20px] lg:bottom-[50px] lg:right-[50px]">
    {ctas.map((cta, index) =>  
      <Link
        key={"cta"+index}
        href={cta.href}
        target={cta.target}
        style={{ borderRadius: '50%', backgroundColor: cta.backgroundColor || '#FFF' }}
        className={`flex h-[50px] w-[50px] lg:h-[80px] lg:w-[80px] cursor-pointer items-center justify-center border-none ml-4 shadow-[0_14px_34px_0px_rgba(0,0,0,0.35)]`}
      >
        <ButtonIcon
          name={cta.icon?.name as IconName}
          styleOverrides={{
            tailwind: 'h-[10px] w-[10px] lg:h-[30px] lg:w-[30px]',
          }}
          color={cta.icon.color}
          placement={cta.icon?.placement as 'right' | 'left'}
          variant={cta.icon?.variant as IconVariant}
        />
      </Link>
    )}
  </motion.div>
};
