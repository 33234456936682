import { DirectusNavigationHeader } from 'config';
import { HeaderLink, HeaderLinkFeatured, HeaderLinkItem, HeaderLinkSection, NavigationHeaderProps } from '../../../components';
import { mapDirectusImage } from '../general';

export const mapDirectusNavigationHeader = (navigationHeader: DirectusNavigationHeader): NavigationHeaderProps => {
  const links: ({ secondary?: boolean } & HeaderLink)[] = navigationHeader.navigation_header_link_items.map(linkItem => {
    let featured: HeaderLinkFeatured | undefined = undefined;
    let sections: HeaderLinkSection[] = [];

    if (linkItem.item.navigation_header_section_items?.length) {
      for (let i = 0; i < linkItem.item.navigation_header_section_items.length; i++) {
        const links: HeaderLinkItem[] = (
          linkItem.item.header_link_items?.filter(li => {
            const index = li.item.navigation_header_section_items.findIndex(si => {
              return parseInt(si.item as unknown as string, 10) === linkItem.item.navigation_header_section_items?.[i].item.id;
            });
            return index !== -1;
          }) || []
        ).map(it => ({
          href: it.item.href,
          label: it.item.label,
        }));

        if (linkItem.item.navigation_header_section_items[i].item.featured && links[0]) {
          featured = {
            title: linkItem.item.navigation_header_section_items[i].item.title,
            image: mapDirectusImage(linkItem.item.navigation_header_section_items[i].item.image),
            link: links[0],
          };
        } else {
          sections.push({
            title: linkItem.item.navigation_header_section_items[i].item.title,
            links,
          });
        }
      }
    }
    const link: { secondary?: boolean } & HeaderLink = {
      href: linkItem.item.href,
      label: linkItem.item.label,
      preview: !!linkItem.item.preview,
      secondary: !!linkItem.item.secondary,
      subnav:
        featured || sections.length
          ? {
              cta: !!linkItem.item.subnavCta,
              featured,
              sections,
            }
          : undefined,
    };

    return link;
  });
  return {
    links: links.filter(l => !l.secondary),
    secondaryLinks: links.filter(l => !!l.secondary),
  };
};
