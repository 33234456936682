import { useMemo } from 'react';
import { useSanitizedTranslation } from './useSanitizedTranslation';

export type HtmlString = { __html: string };
export type HtmlStringMap<T> = { [K in keyof T]: HtmlString };

export const useContent = <T extends string | Record<string, string>>(keys: T, deps: any[] = []): T extends string ? HtmlString : HtmlStringMap<T> => {
  const { t } = useSanitizedTranslation();

  return useMemo(() => {
    if (!keys) return { __html: '' } as T extends string ? HtmlString : HtmlStringMap<T>;

    if (typeof keys === 'string') {
      const content = t(keys);
      return {
        __html: content !== keys ? content : keys,
      } as unknown as T extends string ? HtmlString : HtmlStringMap<T>;
    }

    const contentObj: HtmlStringMap<T> = {} as HtmlStringMap<T>;
    Object.keys(keys).forEach(key => {
      const content = t(keys[key]);
      contentObj[key as keyof T] = { __html: content !== keys[key] ? content : keys[key] };
    });

    if (!(contentObj as any).__html) {
      (contentObj as any).__html = '';
    }
    return contentObj as T extends string ? HtmlString : HtmlStringMap<T>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, ...deps]);
};
