import { createContext, useEffect, useState } from 'react';
import { Website } from '../types';
import { useMeasure } from '@uidotdev/usehooks'

type PageContextType = {
  pageCtx: { host: Website.Enterprise | Website.Corporate | Website.Shopper | ''; isCorporate: boolean };
  setPageCtx: (ctx: { host: Website.Enterprise | Website.Corporate | Website.Shopper | ''; isCorporate: boolean }) => void;
  setTheme: (theme: any) => void;
  theme: any;
  headerHeight: number;
};

export const PageContext = createContext<PageContextType>({ pageCtx: { host: '', isCorporate: false }, setPageCtx: ({}) => {}, theme: {}, setTheme: () => {} });

export const PageProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [pageCtx, setPageCtx] = useState<PageContextType['pageCtx']>({ host: Website.Shopper, isCorporate: false });
  const [theme, setTheme] = useState<any>({});
  
  const [headerRef, { height: headerHeight }] = useMeasure();

  return <PageContext.Provider value={{ pageCtx, setPageCtx, theme, setTheme, headerRef, headerHeight }}>{children}</PageContext.Provider>;
};
