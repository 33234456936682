/* eslint-disable sonarjs/cognitive-complexity */
import { DirectusImageHoldingShapeBlock } from 'config';
import { ImageHoldingShapeBlockProps } from '../../..';
import stripOuterHtmlTags from '../../stripHtmlTags';
import { mapDirectusImage } from '../general';
import { mapDirectusCTAs } from '../general/ctas';

// Map Directus Response to ChevronBlock Props
export const mapDirectusImageHoldingShapeBlockMapping = ({ image, heading, body, cta_items }: DirectusImageHoldingShapeBlock): ImageHoldingShapeBlockProps => {
  return {
    heading: heading ?? '',
    body: stripOuterHtmlTags(body ?? ''),
    cta: mapDirectusCTAs(cta_items ?? [], undefined, false),
    image: mapDirectusImage(image),
  };
};
