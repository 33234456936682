import { DirectusEventsBlock } from 'config';
import { EventsBlockProps } from '../../../components';
import { mapDirectusCTAs, mapDirectusImage } from '../general';

export const mapDirectusEventsBlock = ({ cta_items, heading, body, event_items }: DirectusEventsBlock): EventsBlockProps => {
  return {
    heading,
    body,
    cta: mapDirectusCTAs(cta_items ?? [], undefined, true),
    events:
      event_items?.map(e => {
        return {
          date: e.item.date,
          location: e.item.location,
          title: e.item.title,
          body: e.item.body,
          image: mapDirectusImage(e.item.image),
          cta: mapDirectusCTAs(e.item.cta_items ?? [], undefined),
        };
      }) || [],
  };
};
