import { SUPPORTED_LOCALES } from '../utils/locales';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { isEnterprise } from '../utils';
import { useShowElement } from '../hooks/useShowElement';

type LanguageSelectProps = {
  host: string;
};

const languageMap: Record<string, string> = {
  en: '🇺🇸',
  'en-GB': '🇬🇧',
  fr: '🇫🇷',
  de: '🇩🇪',
  'pt-PT': '🇵🇹',
  'es-ES': '🇪🇸',
  it: '🇮🇹',
};

export const LanguageSelect = ({ host }: LanguageSelectProps) => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const { isVisible: showDropdown, hideElement, showElement } = useShowElement();

  const selectLanguage = (e: React.ChangeEvent<any>) => {
    const currentLocale = router.locale;
    const locale = e.target.id;

    if (currentLocale === locale) {
      return;
    }

    // Replace the locale prefix in the URL
    const path = router.asPath.replace(`/${currentLocale}`, `/${locale}`);
    router.push(path, path, { locale });

    i18n.changeLanguage(locale);
    router.prefetch(router.asPath, undefined, { locale });
  };

  return (
    <ul
      defaultValue={i18n.language}
      className={`language-select hover:weight-600 relative flex w-12 flex-col items-center justify-center rounded-sm ${
        isEnterprise(host) ? 'bg-xumoJet' : 'bg-xumoSmoke'
      }  px-2 py-[2px] text-lg ${isEnterprise(host) ? 'text-xumoWhite' : 'text-xumoBlack'} transition-all hover:cursor-pointer focus:outline-none`}
      onMouseEnter={showElement}
      onMouseLeave={hideElement}
    >
      <p className="font-bold">{languageMap[router.locale ?? 'en']}</p>
      <ul
        className={`${
          showDropdown ? '' : 'hidden'
        } absolute right-0 z-50 float-none w-full border-t max-lg:bottom-[101%] max-lg:rounded-t-md lg:top-[90%] lg:rounded-b-md ${
          isEnterprise(host) ? 'border-t-xumoBlack' : 'border-t-xumoFog'
        } ${isEnterprise(host) ? 'bg-xumoJet' : 'bg-xumoSmoke'} `}
      >
        {SUPPORTED_LOCALES.map(locale => (
          <li
            id={locale}
            className={`select-option w-full text-center transition-all last:rounded-b-md last:pb-2 ${
              isEnterprise(host) ? 'hover:bg-xumoTeal' : 'hover:bg-xumoBerry'
            }`}
            onClick={e => selectLanguage(e)}
            key={locale}
          >
            {languageMap[locale]}
          </li>
        ))}
      </ul>
    </ul>
  );
};
