import _ from 'lodash';
import { GetGeneralPageMetadataResponse, isEnterprise } from '../..';
import { Website } from '../../../types';
import {
  mapDirectusBubbleBlock,
  mapDirectusBulletBlock,
  mapDirectusChannelBlock,
  mapDirectusChannelSearchBlock,
  mapDirectusChevronBlock,
  mapDirectusColorCopyGrid,
  mapDirectusContentBlock,
  mapDirectusCtaColumns,
  mapDirectusEcosystem,
  mapDirectusFaqBlock,
  mapDirectusFooter,
  mapDirectusCtaTilesBlock,
  mapDirectusLeadGenForm,
  mapDirectusNavigationHeader,
  mapDirectusNewsBlock,
  mapDirectusQuoteBlock,
  mapDirectusRadioButtonGallery,
  mapDirectusSignUpPopup,
  mapDirectusSliderBlock,
  mapDirectusSocialProof,
  mapDirectusSubscibeBlock,
  mapDirectusSwirlBlock,
  mapDirectusTvSplashBlock,
  mapDirectusTwoColumnBlock,
  mapDirectusUseCases,
  mapDirectusVerboseDeviceShowcase,
  mapDirectusTestBlock,
  mapDirectusLandingHero,
  mapDirectusRotatingScreensBlock,
  mapDirectusOffersBlock,
  mapDirectusAnchor,
  mapDirectusPressBlock,
  mapDirectusVideoModal,
  mapDirectusGlobeBlockMapping,
  mapDirectusImageHoldingShapeBlockMapping,
  mapDirectusTestimonialsBlock,
  mapDirectusStatsBlockMapping,
  mapDirectusFloatingButton,
  mapDirectusVideoHero,
  mapDirectusEventsBlock,
  
} from '../components';

import { mapDirectusCarousel } from '../components/CarouselMapping';
import { ContentBlock, MappingContentBlock, MappingDirectusPage, MappingPageContent } from '../typings';
import { mapDirectusDisclaimer } from '../components/DisclaimerMapping';

export const mapDirectusPage = (page: MappingDirectusPage): { content: MappingContentBlock[] } => {
  const content = page.content.map(getContentFromCollection).filter(c => !!c) as MappingContentBlock[];
  const modals = page.modals.map(getContentFromCollection).filter(c => !!c) as MappingContentBlock[];

  return {
    content: [...content, ...modals],
  };
};

export const mapDirectusPress = (press: Omit<MappingPageContent, 'collection'>): { content: MappingContentBlock[] } => {
  const page = getContentFromCollection({
    collection: ContentBlock.PressBlock,
    ...press,
  }) as MappingContentBlock;

  console.log('PRESS PAGE', {
    collection: ContentBlock.PressBlock,
    ...press,
  }, page)

  return {
    content: [page],
  };
};

export const mapDirectusTvDevice = (device: Omit<MappingPageContent, 'collection'>): { content: MappingContentBlock[] } => {
  const product = getContentFromCollection({
    collection: 'debug_block',
    item: { 
      type: 'ProductDetailBlock',
      props: { deviceId: device.device.deviceId, size: device.size }
    }
  }) as MappingContentBlock;

  const reviews = getContentFromCollection({
    collection: 'debug_block',
    item: {
      type: 'BazaarVoiceBlock',
      props: { productId: device.device.productId }
    }
  }) as MappingContentBlock;

  return {
    content: [
      product,
      reviews
    ],
  };
};

const getContentFromCollection = (pageContent: MappingPageContent): MappingContentBlock | null => {
  const isDebug = pageContent.collection === 'debug_block'
  if (isDebug) {
    console.log('generating debug component', {
      component: pageContent.item.type,
      props: pageContent.item.props
    })

    return {
      component: pageContent.item.type,
      props: pageContent.item.props
    }
  }

  const component = makeTypeName(pageContent.collection) as ContentBlock;
  if (!contentBlockMap.has(component) || !(typeof contentBlockMap.get(component) === 'function')) {
    console.error(`No mapping found for ${component}`);
    return null;
  }

  if (pageContent.item?.preview_only) {
    console.log('This component will only show on production: ', {component})
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') return null
  }

  return { component, props: contentBlockMap.get(component)!(pageContent.item ?? pageContent) };
};

const contentBlockMap = new Map<string, (args?: any) => Record<string, any>>([
  // [ContentBlock.SocialProof, mapDirectusSocialProof],
  // [ContentBlock.BulletBlock, mapDirectusBulletBlock],
  [ContentBlock.ChevronBlock, mapDirectusChevronBlock],
  // [ContentBlock.ColorCopyGrid, mapDirectusColorCopyGrid],
  [ContentBlock.LeadGenForm, mapDirectusLeadGenForm],
  // [ContentBlock.NavigationHeader, mapDirectusNavigationHeader],
  [ContentBlock.NewsBlock, mapDirectusNewsBlock],
  // [ContentBlock.SwirlBlock, mapDirectusSwirlBlock],
  // [ContentBlock.UseCases, mapDirectusUseCases],
  // [ContentBlock.BubbleBlock, mapDirectusBubbleBlock],
  // [ContentBlock.QuoteBlock, mapDirectusQuoteBlock],
  [ContentBlock.FaqBlock, mapDirectusFaqBlock],
  ['CtaTilesBlock', mapDirectusCtaTilesBlock],
  // [ContentBlock.ChannelBlock, mapDirectusChannelBlock],
  // [ContentBlock.SubscribeBlock, mapDirectusSubscibeBlock],
  [ContentBlock.TvSplashBlock, mapDirectusTvSplashBlock],
  // [ContentBlock.VideoHero, mapDirectusVideoHero],
  [ContentBlock.EventsBlock, mapDirectusEventsBlock],
  [ContentBlock.Footer, mapDirectusFooter],
  // [ContentBlock.TwoColumn, mapDirectusTwoColumnBlock],
  [ContentBlock.CtaColumns, mapDirectusCtaColumns],
  // [ContentBlock.Ecosystem, mapDirectusEcosystem],
  // [ContentBlock.SignUpPopup, mapDirectusSignUpPopup],
  // [ContentBlock.VerboseDeviceShowcase, mapDirectusVerboseDeviceShowcase],
  [ContentBlock.ChannelSearchBlock, mapDirectusChannelSearchBlock],
  // [ContentBlock.Carousel, mapDirectusCarousel],
  // [ContentBlock.SliderBlock, mapDirectusSliderBlock],
  // [ContentBlock.ContentBlock, mapDirectusContentBlock],
  // [ContentBlock.RadioButtonGallery, mapDirectusRadioButtonGallery],
  // [ContentBlock.LandingHero, mapDirectusLandingHero],
  [ContentBlock.RotatingScreensBlock, mapDirectusRotatingScreensBlock],
  // [ContentBlock.OffersBlock, mapDirectusOffersBlock],
  [ContentBlock.Anchor, mapDirectusAnchor],
  [ContentBlock.PressBlock, mapDirectusPressBlock],
  [ContentBlock.Disclaimer, mapDirectusDisclaimer],
  // [ContentBlock.TestimonialsBlock, mapDirectusTestimonialsBlock],
  [ContentBlock.VideoModal, mapDirectusVideoModal],
  // [ContentBlock.AnimatedStatsBlock, mapDirectusStatsBlockMapping],
  [ContentBlock.GlobeBlock, mapDirectusGlobeBlockMapping],
  // [ContentBlock.ImageHoldingShapeBlock, mapDirectusImageHoldingShapeBlockMapping],
  [ContentBlock.FloatingButton, mapDirectusFloatingButton],
]);

export const mapDirectusMetadata = (page: MappingDirectusPage): GetGeneralPageMetadataResponse => ({
  url: `https://www.xumo.com${page.slug ? '/' + page.slug : ''}`,
  description: page.description ?? "Xumo is the world's leading video streaming platform for TV operators.",
  title: page.title ?? 'Xumo',
  image: page.metaimage?.id ?? 'f97ab119-40d6-4905-a996-bfbe838e631c',
  host: Website.Shopper,
});

const makeTypeName = (value: string): string => {
  const capitalized = _.camelCase(value);
  return `${capitalized[0].toUpperCase() + capitalized.substring(1, capitalized.length)}`;
};
