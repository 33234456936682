import { useContext } from 'react';
import { ToastsContext } from '../contexts';
import { useSanitizedTranslation } from '../hooks';

const successIcon = (
  <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
  </svg>
);

const failureIcon = (
  <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
  </svg>
);

export const XumoToast = () => {
  const { toasts, removeToast } = useContext(ToastsContext);
  const { t } = useSanitizedTranslation();

  return (
    <div className={`toast-container pointer-events-none fixed bottom-0 right-0 z-50 m-4 flex w-72 flex-col justify-center p-4`}>
      {toasts.map(({ id, description, type }, index) => {
        setTimeout(() => {
          removeToast(id);
        }, 7500);

        return (
          <div className="toast pointer-events-auto my-2 w-full rounded-lg bg-white p-4 shadow-md" onClick={() => removeToast(id)}>
            <div className="flex items-center">
              <div className={`rounded-full p-2 text-white ${type === 'success' ? 'bg-[#2AAC00]' : 'bg-xumoBerry'}`}>
                {type == 'success' ? successIcon : failureIcon}
              </div>
              <div className="type-body ml-3 font-medium text-xumoLicorice" data-i18n={description}>
                {t(description)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
