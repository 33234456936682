import React from 'react';
import { MagnifyingGlass } from './';
import { useSanitizedTranslation } from '../hooks';

type SearchBarProps = {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeHolder: string;
};

export const SearchBar = ({ value, handleChange, placeHolder }: SearchBarProps) => {
  const { t } = useSanitizedTranslation();

  return (
    <div className="relative flex w-full flex-row items-center justify-center mt-8">
      <input
        className="type-body p-8 w-[64%] border border-2 border-solid border-xumoIce rounded-md focus:border-xumoBlack text-center"
        type="text"
        id="search-bar"
        onChange={handleChange}
        value={value}
      />
      {value === '' && (
        <label
          htmlFor="search-bar"
          className={`pointer-events-none absolute flex items-center h-full justify-center whitespace-nowrap`}
        >
          <p className={`type-body overflow-hidden text-ellipsis whitespace-nowrap text-center `} data-i18n={placeHolder}>
            {t(placeHolder)}
          </p>
        </label>
      )}
    </div>
  );
};
