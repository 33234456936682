import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { motion, useTransform, useSpring } from 'framer-motion';
import { makeVariants } from '../utils/makeVariants';

export type ChevronProps = {
  bgColor: string;
  image?: string;
  progress?: any;
  lottie?: any;
  chevronPlacement?: 'left' | 'right';
};

export const Chevron: React.FC<ChevronProps> = ({ bgColor, image, chevronPlacement, progress, lottie }) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  const [lottieJson, setLottieJson] = useState(null);
  const [uniqueId, setUniqueId] = useState<null | string>(null);

  useEffect(() => {
    if (lottieRef.current && lottieJson) {
      lottieRef.current.setSpeed(0.5);
    }
  }, [lottieJson]);

  useEffect(() => {
    setUniqueId(uuid());
  }, []);

  useEffect(() => {
    if (lottie && !lottieJson) {
      fetch(lottie)
        .then(file => file.json())
        .then(setLottieJson);
    }
  }, [lottie, lottieJson]);

  const springProgress = useSpring(progress, { stiffness: 400, damping: 90 });
  const containerX = useTransform(springProgress, [0, 1], [-100, 100]);
  const imageX = useTransform(springProgress, [0, 1], [175, -25]);

  const imageVariants = makeVariants.slideIn({ initial: { x: 20 }, animate: { transition: { delay: 0.75 } } });

  return !lottie ? (
    <div className={`relative aspect-square h-full w-full ${chevronPlacement === 'left' && 'left-[-10%] lg:left-[-20%]'}`}>
      <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 622.6 657.02" className="h-full w-full object-contain" style={{ x: containerX }}>
        <clipPath id={`${uniqueId}-clippath`}>
          <polygon fill="#000" points="0 0 0 329.55 0 657.02 171.88 657.02 364.79 657.02 612 328.66 365.25 .31 365.02 0 0 0" />
        </clipPath>
        <g id={`${uniqueId}-chevron`} clipPath={`url(#${uniqueId}-clippath)`}>
          <path fill={bgColor} d="M171.96,0l246.9,328.36-247.44,328.66h193.37l247.21-328.36L365.25,.31l-.23-.31H171.96Z" />
          <motion.g style={{ x: imageX }}>
            <motion.image variants={imageVariants} href={`${image}`} preserveAspectRatio="xMidYMid slice" height="657" width="622" />
          </motion.g>
        </g>
      </motion.svg>
    </div>
  ) : (
    <div className={`relative flex aspect-square h-full w-full justify-center ${chevronPlacement === 'left' && 'left-[-10%] lg:left-[-20%]'}`}>
      <svg className="svg absolute">
        <clipPath id="chevron-clip" clipPathUnits="objectBoundingBox">
          <path d="M0,0,0,0.502,0,1,0.281,1,0.596,1,1,0.5,0.597,0,0.596,0,0,0"></path>
        </clipPath>
      </svg>
      <motion.div className="h-full" style={{ x: containerX, aspectRatio: '622.6/657.02', clipPath: 'url(#chevron-clip)' }}>
        <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 622.6 657.02" className="absolute left-[8px] top-0 h-full w-full object-contain">
          <path fill={bgColor} d="M171.96,0l246.9,328.36-247.44,328.66h193.37l247.21-328.36L365.25,.31l-.23-.31H171.96Z" />
        </motion.svg>
        <motion.div style={{ x: imageX }} className="position h-full w-full">

        </motion.div>
      </motion.div>
    </div>
  );
};
