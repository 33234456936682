import React, { useRef } from 'react';
import { NewsStory, NewsStoryProps } from './NewsStory';
import { motion } from 'framer-motion';
import { CTAList, useScrolledTheme, SplitH1, SplitH2, CTA, useSanitizedTranslation, XumoButton as Button } from 'ui';

export type NewsBlockProps = {
  heading: string;
  body?: string;
  stories: NewsStoryProps[];
  headingOne?: boolean;
  tags: any[];
  cta?: CTA[];
};

const variants = {
  initial: (i: number) => ({
    opacity: 0,
    x: -40,
  }),
  animate: (i: number) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { staggerChildren: 0.075, ease: 'easeInOut', duration: 0.5 },
  }),
};

export const NewsBlock = ({ heading = "What's new with Xumo", body, stories, cta, tags, headingOne }: NewsBlockProps) => {
  const { t } = useSanitizedTranslation();

  return (
    <section id="news-section" className="flex w-full flex-col items-start justify-center py-8 max-lg:mt-[75px] lg:py-16 bg-[#f5f7f8] relative z-30">
      <div className="wrapper ">
        <h2 className={`type-heading text-center text-black pb-8`} dangerouslySetInnerHTML={{ __html: t(heading.replace(/'/g, '&rsquo;'))}} />
        {body && <p className={`type-body text-[20px] weight-300`} data-i18n={body} dangerouslySetInnerHTML={{ __html: t(body) }} />}
        <motion.div variants={variants} initial="initial" animate="animate" id="gallery" className="grid grid-cols-1 gap-4 py-6 md:grid-cols-2 lg:grid-cols-3">
          {stories.map((story, index) => (
            <>
              <NewsStory key={story.title} {...story} index={index} />
            </>
          ))}
        </motion.div>
        <motion.div className="flex space-x-5 py-4 justify-center items-center" variants={variants}>
          <Button href="/press">View all news</Button>
        </motion.div>
      </div>
    </section>
  );
};
