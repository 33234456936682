'use client';

import { StatsBlock, StatsBlockProps } from './StatsBlock';
import { XumoGlobe, XumoGlobeProps } from './XumoGlobe';

import React, { useRef } from 'react';
import { SplitH1, SplitH2 } from './';
import { useSanitizedTranslation, useScrolledTheme } from 'ui';
import { motion } from 'framer-motion';

export type GlobeStatsBlockProps = XumoGlobeProps & StatsBlockProps;

const heading = 'Powering streaming across the globe';
const body = '';

const variants = {
  initial: {
    opacity: 0,
    y: 40,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      staggerChildren: 0.03,
    },
  },
};

const headingOne = false;

export const GlobeStatsBlock: React.FC<GlobeStatsBlockProps> = ({ markers, animatedStats, rowStats }) => {
  const { t } = useSanitizedTranslation();
  const Heading = headingOne ? SplitH1 : SplitH2;

  

  const ref = useRef<any>();

  return (
    <div ref={ref} className="relative z-10 py-8 lg:py-24">
      <div className="wrapper w-full">
        <motion.div
          className="content-wrapper-x flex w-full flex-col items-center text-left lg:text-center"
          variants={variants}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          style={{color: '#000000'}}
        >
          <Heading variants={variants} content={heading} className={`${headingOne ? 'type-title' : 'type-heading'} text-inherit transitions-colors z-10 flex flex-col`} />
          {body && (
            <motion.div
              variants={variants}
              className="type-body py-4 text-left lg:w-1/2 lg:text-center"
              data-i18n
              dangerouslySetInnerHTML={{ __html: t(body) }}
            />
          )}
        </motion.div>
        <StatsBlock animatedStats={animatedStats} rowStats={rowStats} />
        
      <div style={{background: 'radial-gradient(#46a0c4 0%, #155772 100%)'}} className="mt-4 py-8 rounded-xl lg:py-16 xumo-globe h-[375px] w-full md:h-[750px] xl:h-[1000px]">
          <XumoGlobe markers={markers} />
        </div>
      </div>
    </div>
  );
};
