import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useCallback } from 'react';
import { useWindowSize } from '../hooks';
import { CTA, IImage } from '../types';
import { Bubble, Button, SplitH1, SplitH2 } from './';
import { Position } from './Bubble';
import { useScrolledTheme, useInViewScroll } from 'ui';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';


const variants = {
  initial: {
    opacity: 0,
    y: 40,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      staggerChildren: 0.03,
      delayChildren: 0.3,
    },
  },
};


const tabs = [{
  label: 'Simplicity',
  labelIcon: { src: '/static/icons/tab-simple-icon.svg'},
  body: 'Plug the Xumo Stream Box into your TV and start watching in minutes. Our shows play instantly, and voice search makes it easy to find what you want to watch.',
  poster: { src: '/static/images/streambox-video-1.png' },
  video: { src: '' },
  videoModal: "#simplicity"
}, {
  label: 'Streaming',
  labelIcon: { src: '/static/icons/tab-content-icon.svg'},
  body: 'Enjoy all the top apps, including Netflix, Disney+, Max, and more. Add 300+ free TV channels with the Xumo Play app.',
  poster: { src: '/static/images/streambox-video-2.png' },
  video: { src: '' },
  videoModal: "#content"
}, {
  label: 'Speed',
  labelIcon: { src: '/static/icons/tab-speed-icon.svg'},
  body: 'With our user-friendly guide, universal discovery allows you to watch your favorite TV shows and movies from all your top apps.',
  poster: { src: '/static/images/streambox-video-3.png' },
  video: { src: '' },
  videoModal: "#speed"
}]

// 
// 

// TODO: types 
export const VideoTabsBlock: React.FC<any> = ({  }) => {
  const { t } = useTranslation('common');
  const { width } = useWindowSize();

  const [ [tabIndex, direction], setPage ] = useState([0,0])
  const currentTab = tabs[tabIndex]

  const header = "Plug into a world of entertainment"
  const body = ""
  const backgroundColor = "rgb(21, 87, 114)"
  const accent = "#000"
  const headingOne = false

  const setTab = useCallback(nid => {
    const newDirection = nid > tabIndex
      ? 1
      : -1
      
    const newId = (nid + tabs.length) % tabs.length
    setPage([newId, newDirection, Math.random()])
  }, [tabIndex])


  const Heading = false ? SplitH1 : SplitH2;

  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent,
    text: '#ffffff',
  });

  return (
    <motion.section variants={variants} initial="initial" animate="animate" ref={ref} className="w-full flex-col py-16 xl:py-24">
      <div className="container relative mx-auto flex flex-col items-center px-8 text-center" style={{color: colors.text}}>
        <Heading variants={variants} content={header} className={`${headingOne ? 'type-title' : 'type-heading'} w-full z-10 flex flex-col inherit text-center`} />
        {body && <motion.div variants={variants} className="type-body py-4" style={{color: colors.text}} data-i18n dangerouslySetInnerHTML={{ __html: t(body) }} />}
        <motion.div className="relative w-full 2xl:w-4/5">
          <motion.div
            key="tabs"
            className="align-center flex flex-row flex-wrap justify-center py-8 text-center"
            variants={variants}
            initial="initial"
            whileInView="animate"
          >
            <div className="flex justify-around w-2/3">
              {tabs.map((tab, index) => <>
                <motion.a animate={{opacity: tabIndex === index ? 1 : 0.55}}  onClick={() => setTab(index)} className="flex flex-col justify-center items-center" style={{color: colors.text}}>
                  <div className="w-[80px] h-[80px] mb-2 relative">
                    <Image src={tab.labelIcon.src} fill />
                  </div>
                  <span className="type-body-bold text-inherit">{tab.label}</span>
                </motion.a>
              </>)}
            </div>
            <div className="flex flex-col md:flex-row w-4/5 mt-8 items-center">
              <div className="w-[200px] h-[200px] md:w-1/3 md:h-[200px] rounded-lg bg-xumoWhite overflow-hidden flex items-center justify-center relative">
                <div className="z-10 w-[100px] h-[100px] relative pointer-events-none">
                  <Image src="/static/icons/play_glow.svg" fill />
                </div>
                <AnimatePresence custom={direction}>
                  <Link key={currentTab.body} legacyBehavior passHref href={currentTab.videoModal}>
                    <motion.a 
                      className="absolute w-full h-full pl-16"     
                      custom={direction}
                      initial={(direction) => ({ 
                        opacity: 0,
                        x: -20 * direction,
                        scale: 1.075
                      })}
                      animate={{
                        opacity: 1,
                        x: 0,
                        scale: 1.075,
                      }} 
                      whileHover={{ scale: 1, transition: { duration: 0.15, restDelta: 0.0001 }}}
                      exit={(direction) => ({ 
                        opacity: 0,
                        x: 20 * direction,
                        scale: 1.075,
                        transition: { duration: 0.15 }
                      })}>
                      <Image src={currentTab.poster.src} fill className="object-cover h-full w-full" />
                    </motion.a>
                  </Link>
                </AnimatePresence>
              </div>
              <motion.div className="w-full md:w-2/3 h-full text-left relative flex items-center">
                <AnimatePresence custom={direction}>
                  <motion.div 
                    className="absolute md:pl-16 max-md:mt-8"
                    key={currentTab.body}     
                    custom={direction}
                    initial={(direction) => ({ 
                      opacity: 0,
                      x: -20 * direction
                    })}
                    animate={{
                      opacity: 1,
                      x: 0
                    }} 
                    exit={(direction) => ({ 
                      opacity: 0,
                      x: 20 * direction,
                      transition: { duration: 0.15 }
                    })}>
                    <div className="type-body text-center md:text-left">{currentTab.body}</div>
{/*                    <Button
                      buttonType="primary"
                      href={currentTab.videoModal}
                      label={'Learn More'}
                      styleOverrides={{
                        css: { backgroundColor: '#fff', color: '#000', marginTop: 15 },
                        tailwind: `h-auto sm:inline-block`,
                      }}
                    />*/}
                  </motion.div>
                </AnimatePresence>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};
