/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion } from 'framer-motion';
import { StackedContent } from 'ui';


export const TextBlock = ({ content, bg }) => {
    return <motion.section className="relative z-40 bg-white w-full flex justify-center" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`${bg} content-wrapper py-16 xl:pt-24`}>
          <StackedContent 
            full
            center
            content={content} />
        </div>
      </div>
    </motion.section>
  }
  