import React from 'react';
import {
  DirectusPage,
  DirectusItemWrapper,
  DirectusBulletBlock,
  DirectusCtaColumns,
  DirectusFullbleedHero,
  DirectusLeadGenForm,
  DirectusSocialProof,
  DirectusSwirlBlock,
  DirectusTwoColumn,
  DirectusUseCases,
  DirectusVerboseDeviceShowcase,
  DirectusFooter,
  DirectusEcosystem,
  DirectusChevronBlock,
  DirectusNavigationHeader,
  DirectusColorCopyGrid,
  DirectusNewsBlock,
  DirectusBubbleBlock,
  DirectusQuoteBlock,
  DirectusFaqBlock,
  DirectusChannelBlock,
  DirectusSubscibeBlock,
  DirectusTvSplashBlock,
  DirectusVideoHero,
  DirectusEventsBlock,
  DirectusChannelSearchBlock,
  DirectusSignUpPopup,
  DirectusCarousel,
  DirectusRadioButtonGallery,
  DirectusContentBlock,
  DirectusLandingHero,
  DirectusAnchor,
  DirectusDisclaimer,
  DirectusAnimatedStatsBlock,
  DirectusGlobeBlock,
  //DirectusTestimonialsBlock,
  DirectusImageHoldingShapeBlock,
  DirectusFloatingButton,
  //DirectusRotatingScreensBlock,
  //DirectusOffersBlock,
} from 'config';

import {
  BulletBlockProps,
  CtaColumnsProps,
  FullbleedHeroProps,
  LeadGenFormProps,
  SocialProofProps,
  SwirlBlockProps,
  TwoColumnProps,
  UseCasesProps,
  VerboseDeviceShowcaseProps,
  FooterProps,
  EcosystemProps,
  ChevronBlockProps,
  NavigationHeaderProps,
  ColorCopyGridProps,
  NewsBlockProps,
  BubbleBlockProps,
  QuoteBlockProps,
  FaqBlockProps,
  ChannelBlockProps,
  SubscibeBlockProps,
  TvSplashBlockProps,
  VideoHeroProps,
  EventsBlockProps,
  ChannelSearchBlockProps,
  SignUpPopupProps,
  CarouselProps,
  RadioButtonGalleryProps,
  ContentBlockProps,
  LandingHeroProps,
  AnchorProps,
  DisclaimerProps,
  StatsBlockProps,
  TestimonialBlockProps,
  ImageHoldingShapeBlockProps,
  FloatingButtonProps,
} from '../../../components';

// TODO: refactor
import { XumoGlobeProps } from '../../../components/XumoGlobe';

export type MappingPageContent =
  | DirectusItemWrapper<DirectusBulletBlock>
  | DirectusItemWrapper<DirectusCtaColumns>
  | DirectusItemWrapper<DirectusFullbleedHero>
  | DirectusItemWrapper<DirectusLeadGenForm>
  | DirectusItemWrapper<DirectusSocialProof>
  | DirectusItemWrapper<DirectusSwirlBlock>
  | DirectusItemWrapper<DirectusTwoColumn>
  | DirectusItemWrapper<DirectusUseCases>
  | DirectusItemWrapper<DirectusVerboseDeviceShowcase>
  | DirectusItemWrapper<DirectusFooter>
  | DirectusItemWrapper<DirectusEcosystem>
  | DirectusItemWrapper<DirectusChevronBlock>
  | DirectusItemWrapper<DirectusNavigationHeader>
  | DirectusItemWrapper<DirectusColorCopyGrid>
  | DirectusItemWrapper<DirectusNewsBlock>
  | DirectusItemWrapper<DirectusBubbleBlock>
  | DirectusItemWrapper<DirectusQuoteBlock>
  | DirectusItemWrapper<DirectusFaqBlock>
  | DirectusItemWrapper<DirectusChannelBlock>
  | DirectusItemWrapper<DirectusSubscibeBlock>
  | DirectusItemWrapper<DirectusTvSplashBlock>
  | DirectusItemWrapper<DirectusVideoHero>
  | DirectusItemWrapper<DirectusEventsBlock>
  | DirectusItemWrapper<DirectusChannelSearchBlock>
  | DirectusItemWrapper<DirectusSignUpPopup>
  | DirectusItemWrapper<DirectusCarousel>
  | DirectusItemWrapper<DirectusRadioButtonGallery>
  | DirectusItemWrapper<DirectusContentBlock>
  | DirectusItemWrapper<DirectusAnchor>
  | DirectusItemWrapper<DirectusDisclaimer>
  | DirectusItemWrapper<DirectusAnimatedStatsBlock>
  | DirectusItemWrapper<DirectusGlobeBlock>
  | DirectusItemWrapper<any> //DirectusTestimonialBlock
  | DirectusItemWrapper<DirectusImageHoldingShapeBlock>
  | DirectusItemWrapper<DirectusFloatingButton>;
//| DirectusItemWrapper<DirectusLandingHero>
//| DirectusItemWrapper<DirectusRotatingScreensBlock>
//| DirectusItemWrapper<DirectusOffersBlock>;

export type MappingComponent =
  | React.FC<BulletBlockProps>
  | React.FC<CtaColumnsProps>
  | React.FC<FullbleedHeroProps>
  | React.FC<LeadGenFormProps>
  | React.FC<SocialProofProps>
  | React.FC<SwirlBlockProps>
  | React.FC<TwoColumnProps>
  | React.FC<UseCasesProps>
  | React.FC<VerboseDeviceShowcaseProps>
  | React.FC<FooterProps>
  | React.FC<EcosystemProps>
  | React.FC<ChevronBlockProps>
  | React.FC<NavigationHeaderProps>
  | React.FC<ColorCopyGridProps>
  | React.FC<NewsBlockProps>
  | React.FC<BubbleBlockProps>
  | React.FC<QuoteBlockProps>
  | React.FC<FaqBlockProps>
  | React.FC<ChannelBlockProps>
  | React.FC<SubscibeBlockProps>
  | React.FC<TvSplashBlockProps>
  | React.FC<VideoHeroProps>
  | React.FC<EventsBlockProps>
  | React.FC<ChannelSearchBlockProps>
  | React.FC<SignUpPopupProps>
  | React.FC<CarouselProps>
  | React.FC<RadioButtonGalleryProps>
  | React.FC<ContentBlockProps>
  | React.FC<AnchorProps>
  | React.FC<DisclaimerProps>
  | React.FC<StatsBlockProps>
  | React.FC<XumoGlobeProps>
  | React.FC<TestimonialBlockProps>
  | React.FC<ImageHoldingShapeBlockProps>
  | React.FC<FloatingButtonProps>;
//| React.FC<LandingHeroProps>
//| React.FC<RotatingScreensProps>
//| React.FC<OffersProps>;

export type MappingProps =
  | BulletBlockProps
  | CtaColumnsProps
  | FullbleedHeroProps
  | LeadGenFormProps
  | SocialProofProps
  | SwirlBlockProps
  | TwoColumnProps
  | UseCasesProps
  | VerboseDeviceShowcaseProps
  | FooterProps
  | EcosystemProps
  | ChevronBlockProps
  | NavigationHeaderProps
  | ColorCopyGridProps
  | NewsBlockProps
  | BubbleBlockProps
  | QuoteBlockProps
  | FaqBlockProps
  | ChannelBlockProps
  | SubscibeBlockProps
  | VideoHeroProps
  | EventsBlockProps
  | TvSplashBlockProps
  | ChannelSearchBlockProps
  | SignUpPopupProps
  | CarouselProps
  | RadioButtonGalleryProps
  | ContentBlockProps
  | AnchorProps
  | DisclaimerProps
  | StatsBlockProps
  | XumoGlobeProps
  | TestimonialBlockProps
  | ImageHoldingShapeBlockProps
  | XumoGlobeProps
  | FloatingButtonProps;
//| LandingHeroProps
//| RotatingScreensBlockProps
//| OffersBlockProps;

export enum ContentBlock {
  BulletBlock = 'BulletBlock',
  CtaColumns = 'CtaColumns',
  FullbleedHero = 'FullbleedHero',
  LeadGenForm = 'LeadGenForm',
  SocialProof = 'SocialProof',
  SwirlBlock = 'SwirlBlock',
  TwoColumn = 'TwoColumn',
  UseCases = 'UseCases',
  VerboseDeviceShowcase = 'VerboseDeviceShowcase',
  Footer = 'Footer',
  Ecosystem = 'Ecosystem',
  ChevronBlock = 'ChevronBlock',
  NavigationHeader = 'NavigationHeader',
  ColorCopyGrid = 'ColorCopyGrid',
  NewsBlock = 'NewsBlock',
  BubbleBlock = 'BubbleBlock',
  QuoteBlock = 'QuoteBlock',
  FaqBlock = 'FaqBlock',
  ChannelBlock = 'ChannelBlock',
  SubscribeBlock = 'SubscribeBlock',
  SliderBlock = 'SliderBlock',
  TvSplashBlock = 'TvSplashBlock',
  VideoHero = 'VideoHero',
  EventsBlock = 'EventsBlock',
  ChannelSearchBlock = 'ChannelSearchBlock',
  SignUpPopup = 'SignUpPopup',
  Carousel = 'Carousel',
  RadioButtonGallery = 'RadioButtonGallery',
  ContentBlock = 'ContentBlock',
  TestBlock = 'TestBlock',
  LandingHero = 'LandingHero',
  RotatingScreensBlock = 'RotatingScreenBlock',
  OffersBlock = 'OffersBlock',
  Anchor = 'Anchor',
  PressBlock = 'PressBlock',
  Disclaimer = 'Disclaimer',
  VideoModal = 'VideoModal',
  AnimatedStatsBlock = 'AnimatedStatsBlock',
  GlobeBlock = 'GlobeBlock',
  TestimonialsBlock = 'TestimonialsBlock',
  ImageHoldingShapeBlock = 'ImageHoldingShapeBlock',
  FloatingButton = 'FloatingButton',
}

export type MappingDirectusPage = DirectusPage & { content: MappingPageContent[]; modals: MappingPageContent[] };

export type MappingContentBlock = {
  component: string;
  props: MappingProps;
};
