import { DirectusFaqBlock } from 'config';
import { FaqBlockProps } from '../../../components';
import { mapDirectusImage } from '../general';

export const mapDirectusFaqBlock = (block: DirectusFaqBlock): FaqBlockProps => {
  return {
    backgroundColor: block.background_color ?? 'white',
    heading: block.heading,
    questions: block.faq_items.map(question => ({
      question: question.item.question,
      answer: question.item.answer,
    })),
  };
};
