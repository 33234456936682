// import React from 'react';
// import { useTranslation } from 'next-i18next';

// export type DisclaimerProps = {
//   disclaimer: string;
//   top?: number;
//   align?: 'center' | 'left' | 'right';
//   offset?: number;
// };

// const Disclaimer: React.FC<DisclaimerProps> = ({ disclaimer, top = 0, align = 'center', offset = 0 }) => {
//   const { t } = useTranslation('common');

//   return (
//     <div className="lg:wrapper relative max-lg:w-full" style={{ top, zIndex: 100, textAlign: align }}>
//       <div
//         style={{ paddingLeft: align === 'left' ? offset : 0, paddingRight: align === 'right' ? offset : 0 }}
//         data-i18n={disclaimer}
//         dangerouslySetInnerHTML={{ __html: t(disclaimer) }}
//       />
//     </div>
//   );
// };

// export default Disclaimer;

import React from 'react';
import { useSanitizedTranslation } from '../hooks';

export type DisclaimerProps = {
  disclaimer: string;
  top?: number;
  align?: 'center' | 'left' | 'right';
  offset?: number;
};

const Disclaimer: React.FC<DisclaimerProps> = ({ disclaimer, top = 0, align = 'center', offset = 0 }) => {
  const { t } = useSanitizedTranslation();

  return (
    <div className="w-full py-16 relative z-20 bg-xumoWhite" style={{ top, zIndex: 5, textAlign: align }}>
      <div
        className="wrapper type-body relative text-[14px]">
        <div
          style={{ paddingLeft: align === 'left' ? offset : 0, paddingRight: align === 'right' ? offset : 0 }}
          data-i18n={disclaimer}
          className="content-wrapper-x"
          dangerouslySetInnerHTML={{ __html: t(disclaimer) }} />

      </div>
    </div>
  );
};

export default Disclaimer;
