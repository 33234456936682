import { CSSProperties } from 'react';
import { CTATarget } from './Enums';

export interface CTA {
  label: string;
  href: string;
  target?: CTATarget;
  isSelected?: boolean;
  backgroundColor?: string;
  textColor?: string;
  handleClick?: () => void;
  icon?: {
    name: string;
    color: string;
    variant: string;
    placement: string;
  };
}

export interface IImage {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
}

export enum FontSize {
  XS = 'text-xs',
  SM = 'text-sm',
  BASE = 'text-base',
  LG = 'text-lg',
  XL1 = 'text-xl',
  XL2 = 'text-2xl',
  XL3 = 'text-3xl',
  XL4 = 'text-4xl',
  XL5 = 'text-5xl',
  XL6 = 'text-6xl',
  XL7 = 'text-7xl',
  XL8 = 'text-8xl',
  XL9 = 'text-9xl',
}

export type StyleOverride =
  | {
      css?: CSSProperties;
      tailwind: string;
    }
  | string
  | CSSProperties;

/**
 * ENUMS
 */
export enum Website {
  Enterprise = 'enterprise',
  Corporate = 'corporate',
  Shopper = 'shopper',
  Play = 'play',
}

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify',
}
