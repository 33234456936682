/* eslint-disable max-len */
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { HeaderLink, MotionImage, XumoButton as Button } from '.';
import { PageContext } from '../contexts';
import { useShowElement } from '../hooks/useShowElement';

import { motion } from 'framer-motion';
import { useSanitizedTranslation } from '../hooks';

type NavigationHeaderLinkItemProps = {
  index: number;
  isMobile: boolean;
  link: HeaderLink;
  styleOverrides?: string;
  isNavOpen: boolean;
  setIsNavOpen: (open: boolean) => void;
};

// type NavigationHeaderLinkItemState = NavigationHeaderLinkItemProps & {
//   handleHideSublinks: () => void;
//   handleShowSublinks: () => void;
//   isHovered: boolean;
//   pathName: string; 
// };

const rewriteTable: Record<string, Record<string, string>> = {
  'tv.xumo.com': {
    staging: 'tv.stg.xumo.com',
    uat: 'tv.qa.xumo.com',
  },
};

const findMatch = (link: string): string | undefined => {
  const possibleRewrites = Object.keys(rewriteTable);
  for (let i = 0; i < possibleRewrites.length; i++) {
    const substring = possibleRewrites[i];
    if (link.includes(substring)) {
      return substring;
    }
  }
};

const checkRewrite = (link: string): string => {
  const { VERCEL_GIT_COMMIT_REF: branch } = process.env;
  const found = findMatch(link);
  const rewrite = found ? rewriteTable[found] && rewriteTable[found][branch as string] : false;
  return rewrite && found ? link.replace(found, rewrite) : link;
};

// const SubListItem = ({ handleHideSublinks, handleShowSublinks, isHovered, isMobile, styleOverrides, link, pathName }: NavigationHeaderLinkItemState) => {
//   const { t } = useTranslation('common');

//   const isCurrentPath = link.subLinks?.some(subLink => subLink.href === pathName);
//   const { pageCtx } = useContext(PageContext);
//   const { host } = pageCtx;

//   const xumoBerry = 'text-xumoBerry';

//   const { theme } = useContext(PageContext);

//   return (
//     <div
//       onMouseEnter={handleShowSublinks}
//       onClick={() => (isHovered ? handleHideSublinks() : handleShowSublinks())}
//       className={`${styleOverrides} ${isMobile ? 'whitespace-nowrap' : ''} group flex cursor-pointer items-center`}
//     >
//       <span
//         className={`${styleOverrides} type-body-bold no-shift flex items-center hover:text-xumoBerry ${isCurrentPath ? '' : 'type-body-bold hover:weight-600'}`}
//         data-i18n={link.label}
//         dangerouslySetInnerHTML={{ __html: t(link.label) }}
//         style={{ color: theme.text }}
//       />
//       {!!link.subLinks?.length && !isMobile && (
//         <ButtonIcon
//           icon={{
//             type: 'chevron-down',
//             color: `#555555`,
//             placement: 'right',
//             styleOverrides: `ml-1 mt-[2px]`,
//           }}
//         />
//       )}
//     </div>
//   );
// };


export const NavigationHeaderLinkItem = ({ index, isMobile, link, styleOverrides, isNavOpen, secondary, setIsNavOpen }: NavigationHeaderLinkItemProps) => {
  const { t } = useSanitizedTranslation();
  const pathName = useRouter().asPath;
  const { isVisible: isHovered, setIsVisible: setIsHovered, hideElement: handleHideSublinks, showElement: handleShowSublinks } = useShowElement(false);
  const labelId = 'nav-item-' + index;
  const labelProps = !isMobile && link.hideMobile
    ? { 'aria-label': t(link.label) }
    : { 'aria-labelledby': labelId }

  return (
    <div className={`${isMobile ? 'flex-col items-start' : ''} justify-center flex relative`} onMouseEnter={handleShowSublinks} onMouseLeave={handleHideSublinks}>
      <div className={`items-center flex`}>
        <Link
          className={`nav-link ${styleOverrides} ${link.href === pathName ? 'weight-700 nav-active' : 'weight-500'} ${isHovered ? 'nav-active' : '' } max-md:text-[18px] text-[14px] xl:text-[16px] hover:weight-750 flex cursor-pointer flex-row justify-center items-center py-2 lg:py-7 h-full transition-colors max-lg:whitespace-nowrap lg:flex-col
          `}
          href={checkRewrite(link.href as string)}
          data-i18n={link.label}
          onClick={() => {setIsNavOpen(false); setIsHovered(false)}}
          {...labelProps}
        >
          <span data-i18n={link.label} className="inline-block text-xumoBlack">
            {link.icon
              ? (!isMobile && link.hideMobile)
                ? <Image src={link.icon} alt="" width={32} height={32} />
                : <span id={labelId}>{t(link.label)}</span>
              : <span id={labelId}>{t(link.label)}</span>}
          </span>
        </Link>

        {link.dropdown && !isMobile && (
          <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: isHovered || isNavOpen ? 1.0 : 0.0,
          }}
          transition={{ duration: 0.25 }}
          style={{ pointerEvents: isHovered || isNavOpen ? 'auto' : 'none' }}
          
          className={`top-[calc(100%)] overflow-clip ${secondary ? '-right-12' : '-ml-12' } p-4 pt-0 text-black lg:absolute max-sm:hidden`}
            >
              <motion.div
              
            initial={{  }}
            animate={{
              y: isHovered || isNavOpen ? 0 : -15.0,
            }}
            transition={{ duration: 0.35, ease: 'easeOut' }}
            className="overflow-clip relative lg:bg-white border-t shadow-lg rounded-b-lg"
          >
            <div className="flex md:flex-row tracking-tight w-[850px]">
             <div className="flex flex-col p-8 pr-16 text-left justify-center grow basis-3/5 py-16">
               <motion.div 
                  initial={{opacity: 0}}
                  animate={{
                    opacity: isHovered || isNavOpen ? 1.0 : 0.0,
                    y: isHovered || isNavOpen ? 0 : -6.0,
                  }}
                  transition={{ delay: 0.1, duration: 0.25, ease: 'easeOut', restDelta: 0.0001, restSpeed: 0.0001}}
                  className="text-[20px] lg:text-[24px] xl:text-[28px] 2xl:text-[32px] weight-500">
                  {t(link.dropdown.title)} 
              </motion.div>
               
              <motion.div 
                  initial={{opacity: 0}}
                  animate={{
                    opacity: isHovered || isNavOpen ? 1.0 : 0.0,
                    y: isHovered || isNavOpen ? 0 : -6.0,
                  }}
                  transition={{ delay: 0.15, duration: 0.25, ease: 'easeOut', restDelta: 0.0001, restSpeed: 0.0001}} className="type-body-base mt-2 sm:tracking-tight">{link.dropdown.description}</motion.div>

               <motion.div 
                  initial={{opacity: 0}}
                  animate={{
                    opacity: isHovered || isNavOpen ? 1.0 : 0.0,
                    y: isHovered || isNavOpen ? 0 : -6.0,
                  }}
                  transition={{ delay: 0.2, duration: 0.25, ease: 'easeOut', restDelta: 0.0001, restSpeed: 0.0001}} className="flex space-x-8 align-start mt-8 justify-start">
                {link.dropdown.buttons.map(button => 
                  <Button href={button.href} onClick={() => setIsHovered(false)} light={button.light}>{button.label}</Button>
                )}
              </motion.div>
             </div>
                <div className="hidden lg:flex grow basis-2/5 overflow-clip">
                  
                  {link.dropdown.image && (
                        <motion.div 
                          animate={{
                            y: isHovered || isNavOpen ? 0 : 5.0,
                          }}
                          transition={{ duration: 0.35, ease: 'easeOut', restDelta: 0.0001, restSpeed: 0.0001}}
                          className="w-full  h-[calc(100%)+4px]   relative ">
                          <Image 
                            fill
                            src={link.dropdown.image.src} 
                            className=" w-full h-full object-cover"
                            alt={link.dropdown.title} />
                        </motion.div>
                        
              )}
                </div>
            </div>
          </motion.div>
          </motion.div>
        )}
        {/*<AnimatePresence>*/}
        {link.subnav && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: isHovered || isNavOpen ? 1.0 : 0.0,
              y: isHovered || isNavOpen ? 0 : -10.0,
            }}
            transition={{ duration: 0.2 }}
            style={{ pointerEvents: isHovered || isNavOpen ? 'auto' : 'none' }}
            className={`max-md:hidden top-[calc(100%)] rounded-b-lg px-2 text-black lg:absolute lg:bg-white lg:shadow-lg lg:py-4`}
          >
            <div className="flex flex-col md:flex-row md:space-x-10 lg:space-x-6">
              {link.subnav.sections?.map((section, id) => (
                <div key={'section-' + index + '-' + id} className="mx-2 flex min-w-[100px] flex-col space-y-1 whitespace-nowrap text-left max-lg:mb-4">
                  <div data-i18n className="weight-500 pb-2 text-sm uppercase text-xumoTeal transition-colors">
                    {t(section.title)}
                  </div>
                  {section.links.map((link, id) => (
                    <Link
                      key={index + id + link.label}
                      onClick={() => setIsNavOpen(false)}
                      data-i18n
                      className="block cursor-pointer text-black transition-all hover:opacity-50 max-md:pl-2"
                      href={checkRewrite(link.href || '')}
                    >
                      {t(link.label)}
                    </Link>
                  ))}
                </div>
              ))}
              {link.subnav.featured && (
                <div className="featured hidden cursor-pointer lg:flex">
                  <Link href={checkRewrite(link.subnav.featured.link.href)}>
                    <div
                      className="relative -top-2 w-[250px] whitespace-nowrap rounded-lg bg-xumoSmoke p-2 pb-0 text-left"
                      style={{ height: 'calc(100% + 1rem)' }}
                    >
                      <div data-i18n className="weight-500 pb-2 text-sm uppercase text-xumoBerry transition-colors">
                        {t(link.subnav.featured.title)}
                      </div>
                      <div className="aspect-video w-full overflow-hidden rounded-lg bg-xumoBerry relative">
                        <Image 
                          fill
                          src={link.subnav.featured.image.src} 
                          className="h-full w-full object-cover transition-all"
                          alt={link.subnav.featured.title} />
                      </div>

                      <div data-i18n className="label -mb-2 whitespace-normal pt-2 text-black transition-all">
                        {t(link.subnav.featured.link.label)}
                      </div>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </div>
      {link.dropdown && isMobile && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity:  1 }}
          transition={{ duration: 0.25 }}
          style={{ pointerEvents: 'auto' }}
          className={`flex flex-col lg:hidden`}>
            {link.dropdown.buttons.map(button => 
              <Link
                className={`${button.href === pathName ? 'weight-700 nav-active' : 'weight-500'} text-[14px] xl:text-[16px] justify-center items-center ml-4 py-2`}
                href={checkRewrite(button.href as string)}
                data-i18n={button.label}
                onClick={() => {setIsNavOpen(false); setIsHovered(false)}}>
                <span data-i18n={button.label} className="inline-block text-xumoBlack">
                  <span>{t(button.label)}</span>
                </span>
              </Link>
            )}
          </motion.div>

          
        )}
    </div>
  );
};
