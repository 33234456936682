import { useSpring, useTransform } from 'framer-motion';
import { useState } from 'react';
import { MotionImage, useInViewScroll } from 'ui';
import { IImage, TwoColumnText, TwoColumnTextProps, UseCase, Usecase } from '..';

export interface UseCasesProps {
  anchor: string;
  backgroundColor?: string;
  twoColumnText: TwoColumnTextProps;
  bgImage: IImage;
  useCases: [Usecase, Usecase, Usecase] | [Usecase, Usecase];
}

export const UseCases = ({ twoColumnText, anchor, useCases, backgroundColor, bgImage }: UseCasesProps) => {
  const [shownCopy, setShownCopy] = useState(0); // index of copy to show. Default first open

  const { ref, progress: y } = useInViewScroll([0, -150], ['start 100vh', 'end 0vh']);
  const rotateAmount = useTransform(y, [0, 100], [0, 5], { clamp: false });
  const rotate = useSpring(rotateAmount, { stiffness: 400, damping: 90 });

  return (
    <section ref={ref} className="w-full overflow-hidden bg-xumoIce" style={{ backgroundColor }}>
      <div
        className="container z-10 mx-auto flex w-full max-w-screen-lg shrink-0 flex-col 
      flex-nowrap items-center justify-between px-6 py-16 md:-mb-[50px] xl:-mb-[180px]"
      >
        <TwoColumnText {...twoColumnText} />
      </div>
      {bgImage.src ? (
        <div className="scswale-125 pointer-events-none relative left-[-50px] top-0 sm:left-[-125px] md:left-[-150px] md:top-[-100px]">
          <MotionImage fill className="relative z-0 md:w-full" src={bgImage.src} alt="" style={{ y, rotate: rotate as any }} />
        </div>
      ) : null}

      <div
        ref={ref}
        id={anchor}
        className="container mx-auto flex w-full max-w-screen-lg shrink-0 flex-col flex-nowrap 
      items-center justify-between px-6 pb-16"
      >
        <ul className="flex min-h-[50vh] w-full basis-auto flex-col justify-center xl:mt-[-8rem]">
          {useCases.map((useCase, index) => {
            return <UseCase useCase={useCase} key={index.toString()} index={index} isShown={shownCopy === index} setShownCopy={setShownCopy} />;
          })}
        </ul>
      </div>
    </section>
  );
};
