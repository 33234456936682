/* eslint-disable max-len */
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { CTAList, SplitH1, SplitH2, CTA, useSanitizedTranslation, Button, MotionImage, IImage } from 'ui';
import Link from 'next/link';

export type XumoEvent = {
  title: string;
  body?: string;
  date: string;
  location: string;
  cta?: CTA;
  image: IImage;
};

export type EventsBlockProps = {
  heading: string;
  body?: string;
  headingOne?: boolean;
  cta?: CTA[];
  events: XumoEvent[];
};

const variants = {
  initial: (i: number) => ({
    opacity: 0,
    x: -40,
  }),
  animate: (i: number) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { staggerChildren: 0.075, ease: 'easeInOut', duration: 0.5 },
  }),
};

export const EventsBlock = ({ cta, heading, headingOne, body, events }: EventsBlockProps) => {
  const { t } = useSanitizedTranslation();

  const HeadingComponent = headingOne ? SplitH1 : SplitH2;

  const ref = useRef<any>();

  return (
    <section ref={ref} id="news-section" className="wrapper relative z-10 flex w-full flex-col items-start justify-center py-8 max-lg:mt-[75px] lg:py-16">
      <HeadingComponent className={`${headingOne ? 'type-title' : 'type-heading'} mb-8 w-full text-center`} content={heading} />
      {body && (
        <div className="w-full text-center">
          <p className={`type-subtitle mb-8`} data-i18n={body} dangerouslySetInnerHTML={{ __html: t(body) }} />
        </div>
      )}
      <motion.div variants={variants} initial="initial" animate="animate" id="gallery" className="flex w-full gap-4 flex-row flex-wrap justify-center items-center">
       {events.map(nextEvent  => <div className="flex flex-col w-full md:w-[65%] lg:w-[40%] xl:w-[30%] rounded-xl overflow-clip bg-white shadow-xl">
          <div className="relative aspect-video w-full shrink-0 overflow-clip">
            <Link href={nextEvent.cta?.href} aria-label={nextEvent.cta?.label || 'More info'} target="_blank">
              <MotionImage src={nextEvent.image.src} whileHover={{scale: 1.05}} transition={{duration: 0.3, restDelta: 0.00001}} alt={nextEvent.image.alt || 'Event cover iamge'} className="h-full w-full object-cover will-change-transform" fill />
            </Link>
          </div>
          <div className="flex flex-col items-start p-4 md:p-8 text-left md:min-h-[400px]">
            <div className="grow items-start text-left">
              <h3 className="type-subheading mb-4" data-i18n={nextEvent.title}>
                {nextEvent.title}
              </h3>
              {nextEvent.body ? <p className="type-body text-sm mb-4 justify-between" dangerouslySetInnerHTML={{ __html: t(nextEvent.body) }} /> : null}
              <div className="text-body mb-4 text-sm">
                <div className="flex items-center">
                  <span className="mr-3 inline-block h-2 w-2 rounded-full bg-xumoTeal" /> {nextEvent.date}
                </div>
                <div className="flex items-center">
                  <span className="mr-3 inline-block h-2 w-2 rounded-full bg-xumoTeal" /> {nextEvent.location}
                </div>
              </div>
            </div>
            {nextEvent.cta ? (
              <Button
                href={nextEvent.cta?.href}
                label={nextEvent.cta?.label || 'More info'}
                buttonType="link-primary"
                styleOverrides={`w-full text-center bg-xumoBlack sm:inline-block`}
              />
            ) : null}
          </div>
        </div>)}
      </motion.div>
      <motion.div className="flex space-x-5 py-5" variants={variants}>
        {cta?.length ? <CTAList ctas={cta} /> : null}
      </motion.div>
    </section>
  );
};
