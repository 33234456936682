import React, { useContext } from 'react';
import { CTA } from '../types';
import Link from 'next/link';
import Image from 'next/image';
import { SubscribeForm, SubscribeFormProps } from '.';
import { isEnterpriseOrShopper } from '../utils';
import { PageContext, useSanitizedTranslation } from '..';

export interface FooterProps {
  sections: { heading: string; links: CTA[] }[];
  contacts?: {
    heading: string;
    ctas: CTA[];
  };
  legal: { heading: string; links: CTA[] };
  form?: SubscribeFormProps;
}

// TODO: CMSify branch rewrites
const rewriteTable = {
  'tv.xumo.com': {
    staging: 'tv.stg.xumo.com',
    uat: 'tv.qa.xumo.com',
  },
};

const findMatch = (link: string) => {
  const possibleRewrites = Object.keys(rewriteTable);
  for (let i = 0; i < possibleRewrites.length; i++) {
    const substring = possibleRewrites[i];
    if (link.includes(substring)) {
      return substring;
    }
  }
};

const checkRewrite = (link: string) => {
  const { VERCEL_GIT_COMMIT_REF: branch } = process.env;
  const found = findMatch(link) as string;
  const rewrite = rewriteTable[found as keyof typeof rewriteTable] && rewriteTable[found as keyof typeof rewriteTable][branch as keyof typeof branch];
  return rewrite ? link.replace(found, rewrite) : link;
};

export const Footer: React.FC<FooterProps> = ({ sections, form, legal, contacts }: FooterProps) => {
  const { t } = useSanitizedTranslation();

  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  return (
    <footer className={`flex w-full items-center justify-center bg-xumoBlack relative z-30`} style={{ zIndex: 5 }} data-testid={'footer-section'}>
      <div className="wrapper flex w-full flex-col pb-3 pt-16">
        <div className="content-wrapper-x flex w-full flex-row flex-wrap [&>div]:basis-full sm:[&>div]:basis-1/2 lg:[&>div]:basis-1/3 xl:[&>div]:basis-0 xl:[&>div]:grow [&>div]:w-0 gap-y-4 md:gap-y-8 xl:gap-8">
          {sections.map(({ links, heading }, index) => (
            <div
              key={index}
              className={`mb-4 flex w-full flex-col  flex-wrap items-start justify-start whitespace-nowrap
              transition-all`}
            >
              <p className={`mb-2 text-left text-lg font-bold text-white`} data-i18n={heading} dangerouslySetInnerHTML={{ __html: t(heading) }} />
              <ul className="flex flex-col items-start justify-start">
                {links.map(({ label, href, target }, index) => (
                  <li key={index} className="flex flex-row items-start justify-start">
                    {/* TODO: Differentiate between internal and external links */}
                    <Link
                      href={checkRewrite(href)}
                      target={target}
                      className={` mr-8 h-8 text-left text-sm transition-colors ${
                        isEnterpriseOrShopper(host) ? 'text-white hover:text-xumoBerry' : 'text-[#333] hover:text-xumoBerry'
                      }`}
                      passHref
                      data-i18n={label}
                      dangerouslySetInnerHTML={{ __html: t(label) }}
                    ></Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {(form || contacts) && (
            <div className="flex min-w-[130px] flex-col justify-between max-[1102px]:flex-col-reverse lg:w-1/3 min-[1102px]:-mb-[62px]">
              {form && <SubscribeForm {...form} />}
              <div className="flex flex-col justify-start pb-8 text-left min-[1102px]:mt-6">
                {contacts?.heading ? (
                  <p className="mb-2 text-lg font-bold text-xumoBerry" dangerouslySetInnerHTML={{ __html: t(contacts.heading) }} />
                ) : null}
                {contacts?.ctas.map((cta, index) => (
                  <a
                    href={checkRewrite(cta.href)}
                    key={index}
                    target={cta.target}
                    className={`text-xumoBerry break-words font-bold`}
                  >
                    {t(cta.label)}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="self-start content-wrapper-x mb-5 mt-8">
          <Image
            src={`/static/images/xumo-logo-white.png`}
            width={125}
            height={29}
            style={{ width: 125, height: 29 }}
            alt="Xumo"
          />
        </div>
        <div className="content-wrapper-x">
          <hr className=" flex w-full flex-row justify-between border-b-[1px] border-t-0 border-xumoCharcoal" />
        </div>
        <p className="content-wrapper-x mt-4 mb-8 self-start">
          <span className="text-left text-sm text-xumoGrey" data-i18n={legal.heading} dangerouslySetInnerHTML={{ __html: t(legal.heading) }} />
          {legal.links.map(({ label, href, target }, index) => (
            <span key={index}>
              <span className="text-sm text-xumoCharcoal"> | </span>
              <Link
                href={checkRewrite(href)}
                target={target}
                passHref
                className="text-sm text-xumoGrey"
                data-i18n={label}
                dangerouslySetInnerHTML={{ __html: t(label) }}
              ></Link>
            </span>
          ))}
        </p>
      </div>
    </footer>
  );
};
