/* eslint-disable max-len */
import { motion, Variants } from 'framer-motion';
import { Button, IBullet } from '.';
import { useContent } from '../hooks';

type BulletProps = IBullet & {
  variants: Variants;
};

export const Bullet = ({ heading, body, variants, cta }: BulletProps) => {
  const { heading: headingContent, body: bodyContent } = useContent({ heading: heading ?? '', body: body ?? '' });
  return (
    <motion.div
      variants={variants}
      className={`mb-7 flex w-full flex-col justify-between border-solid 
      border-[#155772] first:pl-0 first:pr-12 last:border-r-0 last:pr-0 max-md:pb-8 max-[767px]:first:pr-0  md:mb-0 md:min-h-[124px] md:w-1/3 md:border-r md:px-4 md:first:pr-6 `}
    >
      {heading && <h3 className="type-h3 weight-700 mb-2 w-2/3" dangerouslySetInnerHTML={headingContent} data-i18n={heading} />}
      {body && <div className="type-body weight-500 mb-4 " dangerouslySetInnerHTML={bodyContent} data-i18n={body} />}
      {cta && (
        <Button
          buttonType="link-primary"
          href={cta.href}
          label={cta.label}
          styleOverrides={{
            css: { backgroundColor: '#155772', color: '#FFF', marginTop: 15 },
            tailwind: `h-auto`,
          }}
        />
      )}
    </motion.div>
  );
};
