import { DirectusGlobeBlock } from 'config';
import { mapDirectusImage } from '../general';
import { GlobeStatsBlockProps } from '../../../components';

export const mapDirectusGlobeBlockMapping = (block: DirectusGlobeBlock): GlobeStatsBlockProps => {
  return {
    markers: block.locations.map(l => {
      const location = l.globe_data_id;
      return {
        name: location.name,
        logos: location.logos?.map(logo => mapDirectusImage(logo)) || [],
        lat: location.location?.coordinates[0] as number,
        long: location.location?.coordinates[1] as number,
        channels: location.num_channels || 0,
      };
    }),
    rowStats: block.row_stats,
    animatedStats: block.animated_stats,
  };
};
