/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence } from 'framer-motion';

import React, { useContext, useRef, useEffect } from 'react';
import { Button, PageContext, useInViewScroll } from 'ui';

import { MotionImage, SplitH1, SplitH2 } from './';

const variants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

const copyVariants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,

      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 80,
      damping: 23,
    },
  },
};

const Circle: React.FC<{ firstSrc: string; secondSrc?: string; isInView?: boolean }> = ({ firstSrc, secondSrc, isInView }) => {
  const delay = useRef(Math.random() * 0.6);

  return (
    <motion.div
      variants={variants}
      className="keyart relative inline-block aspect-square h-[100px] shrink-0 overflow-hidden rounded-full border-2 md:h-[14vh] lg:h-[16vh]"
      style={{ borderColor: '#155772' }}
    >
      <MotionImage
        key={firstSrc}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: delay.current, duration: 0.4 }}
        src={firstSrc}
        className="absolute left-0 top-0 h-full w-full object-cover"
        alt="circle"
      />
    </motion.div>
  );
};

export const Timeline: React.FC<{ firstSrc: string; secondSrc?: string; isInView?: boolean; layout?: any }> = ({ firstSrc, secondSrc, isInView, layout }) => {
  return (
    <motion.div
      variants={variants}
      className="keyart relative flex h-[15vh] w-[2000px] shrink-0 flex-row space-x-2 overflow-hidden rounded-lg border-2 p-2 p-2"
      style={{ borderColor: '#155772', color: '#000' }}
    >
      <div className="relative z-50 w-[500px] space-y-2 rounded-lg border-2 p-2 text-left" style={{ borderColor: '#15577233' }}>
        <div className="z-10 h-[30px] w-[150px] rounded-lg bg-xumoAegean opacity-50"></div>
        <div className="z-10 h-[30px] w-[50px] rounded-lg bg-xumoAegean opacity-50"></div>
      </div>
      <div className="relative z-50 w-[300px] space-y-2 rounded-lg border-2 p-2 text-left" style={{ borderColor: '#15577233' }}>
        <div className="z-10 h-[30px] w-[175px] rounded-lg bg-xumoAegean opacity-50"></div>
        <div className="z-10 h-[30px] w-[50px] rounded-lg bg-xumoAegean opacity-50"></div>
      </div>
      <div className="relative z-50 w-[500px] space-y-2 rounded-lg border-2 p-2 text-left" style={{ borderColor: '#15577233' }}>
        <div className="z-10 h-[30px] w-[120px] rounded-lg bg-xumoAegean opacity-50"></div>
        <div className="z-10 h-[30px] w-[50px] rounded-lg bg-xumoAegean opacity-50"></div>
      </div>
      <div className="relative z-50 w-[300px] space-y-2 rounded-lg border-2 p-2 text-left" style={{ borderColor: '#15577233' }}>
        <div className="z-10 h-[30px] w-[200px] rounded-lg bg-xumoAegean opacity-50"></div>
        <div className="z-10 h-[30px] w-[50px] rounded-lg bg-xumoAegean opacity-50"></div>
      </div>
    </motion.div>
  );
};

export const TimelineLandingPage = ({}) => {
  const { setTheme } = useContext(PageContext);
  const { ref, progress } = useInViewScroll([0, 1], ['start 20vh', 'end 0vh']);
  const amt = useSpring(progress, { stiffness: 400, damping: 90 });

  const containerX = useTransform(amt, [0, 1], [0, 100]);
  const container2 = useTransform(amt, [0, 1], [0, -100]);
  const containerY = useTransform(amt, [0, 1], ['0vh', '-15vh']);

  const playSection = useRef(null);
  const isInView = useInView(playSection, { margin: '-50% 0px -25% 0px' });
  const componentInView = useInView(ref, { margin: '-50% 0px -50% 0px' });

  useEffect(() => {
    if (componentInView) {
      setTheme({
        background: '#E0E7ED',
        accent: '#155772',
        text: '#000000',
      });
    }
  }, [componentInView, setTheme]);

  return (
    <>
      <motion.section animate={{ opacity: componentInView ? 1 : 0.1 }} ref={ref} className="border-3 w-full" data-testid={'keyart-section'}>
        <motion.div
          initial="hidden"
          animate="show"
          variants={variants}
          className="wrapper relative flex w-full flex-col justify-center text-left text-black max-lg:py-[100px] lg:h-[100vh] lg:min-h-[500px]"
        >
          <div className="w-full lg:w-1/2">
            <SplitH1 content={'Harness<br/>the power<br/>of streaming'} variants={copyVariants} className={`type-title w-full leading-none lg:w-4/5`} />

            <motion.p variants={copyVariants} className={`type-body w-full max-w-sm pt-6`}>
              Monetize your content with your own streaming channel
            </motion.p>
            <motion.div variants={copyVariants} className="space-x-6 pt-2">
              <Button label={'For content owners'} href="#content-owners" buttonType="link-primary" styleOverrides={`bg-xumoAegean inline-block`} />
            </motion.div>
            <motion.p variants={copyVariants} className={`type-body w-full max-w-sm pt-6`}>
              Enhance your streaming capabilities with our specialized technology
            </motion.p>
            <motion.div variants={copyVariants} className="space-x-6 pt-2">
              <Button label={'For distributors'} href="#distributors" buttonType="link-primary" styleOverrides={`bg-xumoAegean inline-block`} />
            </motion.div>
            <motion.p variants={copyVariants} className={`type-body w-full max-w-sm pt-6`}>
              Target your ads to new audiences
            </motion.p>
            <motion.div variants={copyVariants} className="space-x-6 pt-2">
              <Button label={'For advertisers'} href="#advertisers" buttonType="link-primary" styleOverrides={`bg-xumoAegean inline-block`} />
            </motion.div>
          </div>
        </motion.div>

        <div className="pointer-events-none relative bottom-0 left-0 right-0 h-[700px] overflow-hidden max-lg:z-10  md:h-[800px] lg:fixed lg:top-0 lg:h-[120vh]">
          <motion.div style={{ y: containerY }} className="wrapper max-lg:transform-none">
            <AnimatePresence>
              {componentInView && (
                <motion.div
                  key="right-keyart"
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={variants}
                  className="relative left-[-30%] w-[120%] space-y-3 md:left-[-15%] lg:absolute lg:left-[50%] lg:top-[200px] lg:space-y-8"
                >
                  <motion.div style={{ x: containerX }} className="relative left-[5%] flex space-x-3 lg:space-x-8">
                    <Circle
                      isInView={isInView || !componentInView}
                      firstSrc="/bubbles/channel-XumoFreeNature&WildlifeTV.jpg"
                      secondSrc="/weather-channel.webp"
                    />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-americasTestKitchen.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-dateline.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-divorceCourt.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-FilmRise-IronChef.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-filmrisetruecrime.jpg" secondSrc="/weather-channel.webp" />
                  </motion.div>

                  <motion.div style={{ x: container2 }} className="relative left-[0%] flex space-x-3 lg:space-x-8">
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-XumoFreeActionMovies.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-FilmriseWestern.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-Forensic-Files.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-Hallmark-Movies.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-HellsKitchen.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-ION.jpg" secondSrc="/weather-channel.webp" />
                  </motion.div>

                  <motion.div style={{ x: containerX }} className="relative left-[5%] flex space-x-3 lg:space-x-8">
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-XumoFreeDocumentaries.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-IONMystery.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-KitchenNightmares.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-maverick.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-Midsomer-Murders.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/weather-channel.webp" secondSrc="/weather-channel.webp" />
                  </motion.div>

                  <motion.div style={{ x: container2 }} className="relative left-[10%] flex space-x-3 lg:space-x-8">
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-XumoFreeFamilyMovies.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-HellsKitchen.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-ION.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-Motorvision-TV.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-nbc-news-now.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/logo-today.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/weather-channel.webp" secondSrc="/weather-channel.webp" />
                  </motion.div>

                  <motion.div style={{ x: containerX }} className="relative left-[5%] flex space-x-3 lg:space-x-8">
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-XumoFreeFoodTV.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-PGATour.jpg" secondSrc="/weather-channel.webp" />
                    <Circle
                      isInView={isInView || !componentInView}
                      firstSrc="/bubbles/channel-XumoFreeNature&WildlifeTV.jpg"
                      secondSrc="/weather-channel.webp"
                    />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-americasTestKitchen.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/weather-channel.webp" secondSrc="/weather-channel.webp" />
                  </motion.div>

                  <motion.div style={{ x: container2 }} className="relative left-[10%] flex space-x-3 max-lg:hidden lg:space-x-8">
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-XumoFreeHorror&Thriller.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-TODAYAllDay.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-HellsKitchen.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-ION.jpg" secondSrc="/weather-channel.webp" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/logo-hallmark.jpg" secondSrc="/weather-channel.webp" />
                  </motion.div>
                </motion.div>
              )}

              {/*{componentInView && (
                <motion.div
                  key="left-keyart"
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={variants}
                  className="align-end absolute right-[105%] flex w-full flex-col space-y-8 text-right"
                >
                  <motion.div style={{ x: container2 }} className="relative right-[0%] flex flex-row-reverse space-x-4 lg:space-x-8 space-x-reverse">
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                  </motion.div>
                  <motion.div style={{ x: containerX }} className="relative right-[5%] flex flex-row-reverse space-x-4 lg:space-x-8 space-x-reverse">
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                  </motion.div>
                  <motion.div style={{ x: container2 }} className="relative right-[15%] flex flex-row-reverse space-x-4 lg:space-x-8 space-x-reverse">
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                  </motion.div>
                  <motion.div style={{ x: containerX }} className="flexflex-row-reverse relative right-[5%] space-x-4 lg:space-x-8 space-x-reverse">
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                  </motion.div>
                  <motion.div style={{ x: container2 }} className="flexflex-row-reverse relative right-[0%] space-x-4 lg:space-x-8 space-x-reverse">
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                    <KeyartBlock isInView={isInView} firstSrc="/orange-swirl-bg.webp" secondSrc="/keyart-1.webp" />
                  </motion.div>
                </motion.div>
              )}*/}
            </AnimatePresence>
          </motion.div>
        </div>

        <div ref={playSection} className="container relative mx-auto w-full px-10 pb-[100px] text-left text-black lg:pb-[300px]">
          <motion.div variants={variants} initial="hidden" whileInView="show" viewport={{ once: true, margin: '-40% 0% -40% 0%' }}>
            <SplitH2 content={'30+'} variants={copyVariants} className={`type-title w-1/2 text-6xl text-xumoAegean`} />
            <motion.p variants={copyVariants} className={`type-body w-1/2 max-w-sm pb-4 text-lg`}>
              territories supported around the world
            </motion.p>
            <SplitH2 content={'700+'} variants={copyVariants} className={`type-title w-1/2 text-6xl text-xumoAegean`} />
            <motion.p variants={copyVariants} className={`type-body w-1/2 max-w-sm pb-4 text-lg`}>
              globally powered channels
            </motion.p>
            <SplitH2 content={'18+'} variants={copyVariants} className={`type-title w-1/2 text-6xl text-xumoAegean`} />
            <motion.p variants={copyVariants} className={`type-body w-1/2 max-w-sm pb-4 text-lg`}>
              hours streamed per year
            </motion.p>
            <SplitH2 content={'10B+'} variants={copyVariants} className={`type-title w-1/2 text-6xl text-xumoAegean`} />
            <motion.p variants={copyVariants} className={`type-body w-1/2 max-w-sm pb-4 text-lg`}>
              ad impressions generated per year
            </motion.p>
            <SplitH2 content={'50+'} variants={copyVariants} className={`type-title w-1/2 text-6xl text-xumoAegean`} />
            <motion.p variants={copyVariants} className={`type-body w-1/2 max-w-sm pb-4 text-lg`}>
              DSP and SSP partnerships
            </motion.p>
          </motion.div>
        </div>
        {/*@ts-ignore */}
        <style jsx global>{`
          body {
            background: #e0e7ed;
            transition: background 0.5s;
          }
        `}</style>
      </motion.section>
    </>
  );
};
