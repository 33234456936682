'use client';

import React, { useRef } from 'react';
import { XumoGlobe } from './XumoGlobe';
import { SplitH1, SplitH2 } from './';
import { useSanitizedTranslation, useScrolledTheme } from 'ui';
import { motion } from 'framer-motion';

const heading = 'Powering streaming across the globe';
const body = '';

const variants = {
  initial: {
    opacity: 0,
    y: 40,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      staggerChildren: 0.03,
    },
  },
};

export const GlobeBlock: React.FC<{ headingOne?: boolean }> = ({ headingOne }) => {
  const { t } = useSanitizedTranslation();
  const Heading = headingOne ? SplitH1 : SplitH2;

  const ref = useRef<any>();
  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#E0E7ED',
    accent: '#2AAC00',
    text: '#000000',
  });

  return (
    <div ref={ref} className="relative z-10 py-[50px]">
      <motion.div
        className="wrapper flex w-full flex-col items-center text-left lg:text-center"
        variants={variants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        <Heading variants={variants} content={heading} className={`${headingOne ? 'type-title' : 'type-heading'} z-10 flex flex-col`} />
        {body && (
          <motion.div
            variants={variants}
            className="type-body py-4 text-left lg:w-1/2 lg:text-center"
            data-i18n
            dangerouslySetInnerHTML={{ __html: t(body) }}
          />
        )}
      </motion.div>
      <motion.div
        className="wrapper flex w-full flex-col items-center text-left lg:text-center"
        variants={variants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        
      </motion.div>
      <div className="wrapper bg-[#155772] globe h-[500px] w-full md:h-[750px] xl:h-[1000px] rounded-xl overflow-clip">
        <XumoGlobe />
      </div>
    </div>
  );
};
