/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useInView, useAnimate, stagger } from 'framer-motion';

import { useRef, useState, useEffect } from 'react';
import { useSanitizedTranslation, XumoButton as Button, ImageButton } from 'ui';
import classNames from 'classnames';
import Image from 'next/image';

const Content = ({ item, noOrphans }) => {
    const [ key, value ] = Object.entries(item)[0]

    if (!value) return <></>

    switch (key) {
      case 'heading': return <h2 className="type-heading m-0 p-0" dangerouslySetInnerHTML={{__html: value}} />
      case 'headingAlt': return <h2 className="type-heading-alt m-0 p-0" dangerouslySetInnerHTML={{__html: value}} />
      case 'subheading': return <h2 className="type-subheading m-0 p-0" dangerouslySetInnerHTML={{__html: value}} />
      case 'subheadingAlt': return <h2 className="type-subheading-alt m-0 p-0" dangerouslySetInnerHTML={{__html: value}} />
      case 'body': return <div className="content type-body m-0 p-0" 
        dangerouslySetInnerHTML={{
          __html: noOrphans
            ? value.replace(/ (?!.* )/, '&nbsp;').replace(/ (?!.* )/, '&nbsp;')
            : value
        }} />
      case 'bodySmall': return <div className="content type-body-small m-0 p-0" dangerouslySetInnerHTML={{__html: value}} />
      case 'bodyBase': return <div className="content type-body-base m-0 p-0" dangerouslySetInnerHTML={{__html: value}} />
      case 'image': return <Image
        src={value.src}
        alt={value.alt || ""}
        width={value.width}
        height={value.height}
        className="inline-block img"
        style={{height: value.renderedHeight, width: 'auto'}}
        sizes='100vw' />
      case 'ctaRow': return <div className="btn-row [&_*]:m-1 md:[&_*]:m-4 flex-col md:flex-row">
        {value.map(button => 
          button.image 
            ? <ImageButton {...button} />
            : <Button {...button} />
        )}
      </div>
      default: return <></>
    }
    return <></>
  }
  

export const StackedContent = ({ content, full, center, mobileCenter, light, noOrphans, margin }) => {
    const { t } = useSanitizedTranslation();
  
    const [ref, animate] = useAnimate()
    const inView = useInView(ref, { once: true, margin: margin || '-30% 0% -30% 0%'});
  
    useEffect(() => {
      if (inView) {
        animate("p, h1, h2, h3, h4, .img, .content, .btn", {
            y: [30, 0], 
            opacity: [0, 1] 
        }, { 
          ease: [0,1,1,1], 
          duration: 0.8, 
          delay: stagger(0.15),
          deltaRest: 0.000001,
          deltaSpeed: 0.000001
        })
      }
    }, [inView])
  
    const classes = classNames(
      { "w-full md:w-3/4": full },
      { "text-center": center },
      { "[&_>_.btn-row]:flex [&_>_.btn-row]:items-center [&_>_.btn-row]:justify-center": center },

      
      { "max-md:text-center": mobileCenter },
      { "max-md:[&_>_.btn-row]:flex max-md:[&_>_.btn-row]:items-center max-md:[&_>_.btn-row]:justify-center": mobileCenter },

      { "text-white max-lg:text-center max-lg:[&_>_.btn-row]:flex max-lg:[&_>_.btn-row]:items-center max-lg:[&_>_.btn-row]:justify-center": light },
      { "[&_>_.btn-row]:-ml-4": !center },
      { "[&_>_h1]:pb-4 [&_>_h2]:pb-4": true },
      'space-y-4'
    )
    
    return <div className="flex justify-center items-center h-full w-full">
      <motion.div 
        ref={ref} 
        initial={{opacity: 0}}
        whileInView={{opacity: 1}}
        viewport={{ once: true, margin: margin || '-30% 0% -30% 0%'}}
        className={classes}>
        {content.map(item => <Content item={item} noOrphans={noOrphans}/>)}
      </motion.div>
    </div>
  }