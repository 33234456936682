import React, { useRef } from 'react';
import { CTAList, CTAListProps, Text, TextProps } from '.';
import { Alignment, matchesComponentProps } from '../types';
import { motion } from 'framer-motion';
import { useScrolledTheme } from 'ui';

export enum Collection {
  TEXT = 'text',
  IMAGES = 'image_items',
  CTAS = 'cta_items_list',
  COLUMNS = 'content_columns',
}

export type ContentBlockProps = {
  sections: ContentSectionProps<Collection>[];
  backgroundColor?: string;
  isColumn?: boolean;
  headingOne?: boolean;
};

export type SectionPropsByCollection<Col extends Collection> = Col extends Collection.TEXT
  ? TextProps
  : Col extends Collection.CTAS
  ? CTAListProps
  : Col extends Collection.COLUMNS
  ? ContentSectionProps<Col>[]
  : Col extends Collection.IMAGES
  ? any // TODO: Fill in with image item type
  : never;

export type ContentSectionPropsUnion = TextProps | CTAListProps | ContentSectionProps<Collection>[];
export type ContentSectionProps<Col extends Collection> = {
  collection: Col;
  item: SectionPropsByCollection<Col>;
};

export const ContentBlock: React.FC<ContentBlockProps> = ({ sections, headingOne, backgroundColor, isColumn = false }) => {
  const ref = useRef<any>();
  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent: '#000000',
    text: '#000000',
  });

  return (
    <section
      ref={ref}
      className={`flex w-full flex-col max-lg:px-6 ${headingOne ? 'max-lg:pb-[50px] lg:pb-[100px]' : 'max-lg:py-[50px] lg:py-[100px]'}`}
      data-test-id={!isColumn ? 'content-block' : null}
    >
      <motion.div
        initial={{ opacity: 0.2 }}
        whileInView={{ opacity: 1.0 }}
        viewport={{ margin: '-50% 0px -50% 0px' }}
        className="container mx-auto flex flex-col rounded-xl p-6 lg:p-24"
      >
        {sections.map(({ collection, item }, index) => {
          item = item as SectionPropsByCollection<typeof collection>;
          switch (collection) {
            case Collection.TEXT:
              matchesComponentProps(item, Text);
              return <Text key={'text' + index} {...item} styleOverrides={{ tailwind: 'mt-4' }} />;
            case Collection.IMAGES:
              return <div key={'img' + index}>Image Items</div>;
            case Collection.CTAS:
              matchesComponentProps(item, CTAList);
              return <CTAList key={'ctalist' + index} ctas={item} alignment={Alignment.Center} styleOverrides={{ tailwind: 'mt-4' }} />;
            case Collection.COLUMNS:
              return (
                <div key={'columns' + index} className={`flex w-full flex-col ${'justify-around'} py-4 md:flex-row`}>
                  {item.map((column: SectionPropsByCollection<Collection.COLUMNS>[0]) => {
                    matchesComponentProps(item, ContentBlock);
                    // TODO: Fix this
                  })}
                </div>
              );
            default:
              return null;
          }
        })}
      </motion.div>
    </section>
  );
};
