/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion } from 'framer-motion';
import React, { useCallback } from 'react';
import { CopySection, CTA, DeviceImage, IImage, useScrolledTheme } from 'ui';
import { useWindowSize } from '../hooks/useWindowSize';
import { makeVariants } from '../utils/makeVariants';
import Head from 'next/head';
import Image from 'next/image';

export interface VerboseDeviceShowcaseProps {
  backgroundColor: string;
  primary: string;
  heading?: string;
  body?: string;
  imageLeft: IImage;
  imageTop: IImage;
  imageBottom?: IImage;
  imageMobile?: IImage;
  iosAppCta?: CTA;
  androidAppCta?: CTA;
  ctaImage?: IImage;
}

export const VerboseDeviceShowcase: React.FC<VerboseDeviceShowcaseProps> = ({
  heading,
  body,
  backgroundColor,
  primary,
  imageLeft,
  imageTop,
  imageBottom,
  imageMobile,
  iosAppCta,
  androidAppCta,
}) => {
  const windowSize = useWindowSize();
  const variants = makeVariants.slideIn();

  const ref = React.useRef<HTMLInputElement>(null);
  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent: primary,
    text: '#000000',
  });

  const getApplicationJsonLd = useCallback(
    (os: string) => {
      const obj = {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: heading,
        operatingSystem: os === 'iOS' ? 'iOS' : 'android',
        applicationCategory: 'Multimedia',
        description: body,
        image: imageTop,
      };

      return {
        __html: JSON.stringify(obj),
      };
    },
    [body, heading, imageTop],
  );

  return (
    <motion.section
      ref={ref}
      className={`container mx-auto w-full px-8 py-[50px] lg:py-[100px] ${
        imageBottom?.src ? 'min-h-[1200px] sm:min-h-[800px] lg:min-h-[1100px]' : 'min-h-[1000px] sm:min-h-[800px] lg:min-h-[1100px]'
      } `}
      data-testid="verbose-device-showcase-section"
    >
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getApplicationJsonLd('iOS')} />
      </Head>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getApplicationJsonLd('android')} />
      </Head>
      <motion.div
        variants={variants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true, amount: 0.5 }}
        className={` flex h-full w-full flex-col ${heading || body ? 'py-24' : ''} lg:flex-row xl:px-0 `}
      >
        <div className="relative z-10 flex h-full w-full flex-col justify-start text-left">
          <div className="w-[80%] max-sm:w-full">
            <CopySection
              heading={heading}
              body={body}
              config={{
                isColumn: false,
                largeHeading: false,
                withDivider: false,
                variantOverride: variants,
              }}
            />

            {iosAppCta || androidAppCta ? (
              <div className="flex space-x-2">
                {iosAppCta ? (
                  <a
                    className="transition-all hover:opacity-80"
                    href={'https://apps.apple.com/us/app/xumo-play-stream-tv-movies/id1068337978'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src="/apple-store.svg" alt="apple-store" width={150} height={150} />
                  </a>
                ) : null}
                {androidAppCta ? (
                  <a
                    className="transition-all hover:opacity-80"
                    href={'https://play.google.com/store/apps/details?id=com.xumo.xumo'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src="/google-play.svg" alt="google-play" width={150} height={150} />
                  </a>
                ) : null}
              </div>
            ) : null}
          </div>
          <DeviceImage
            id={'left'}
            image={imageLeft}
            styleOverrides={`absolute pointer-events-none z-[9] w-full md:top-[310px]  md:w-4/5 left-0 w-1/2 lg:left-[-10%] lg:w-[100%] max-md:h-[348px] max-sm:left-[50%] ${
              imageMobile?.src ? 'max-sm:top-[600px]' : 'max-sm:top-[466px]'
            } ${
              imageBottom
                ? `top-[350px] md:top-[320px] left-[-250px] h-[300px] md:left-[-200px] md:h-[440px] lg:top-[450px] lg:h-[580px]`
                : `top-[200px] left-[-60px] h-[500px] md:h-[540px] lg:top-[420px] lg:h-[580px] `
            }`}
            windowSize={windowSize}
          />
          {imageMobile && imageMobile.src && (
            <DeviceImage
              id={'mobile'}
              image={imageMobile}
              styleOverrides={`absolute pointer-events-none max-sm:top-[210px] top-[190px] right-[-20%] z-[-1] hidden lg:flex h-[400px] min-w-[40%] md:w-[30%] justify-end object-contain md:top-[-40px] lg:right-[-50%] xl:right-[-30%]  lg:w-[50%] lg:right-[-25%]`}
              windowSize={windowSize}
              configOverrides={{
                mobile: { attribute: 'x', values: [-100, 0] },
                desktop: { attribute: 'y', values: [-75, 75] },
              }}
            />
          )}
        </div>
        <div className="relative z-[9] h-full w-full lg:max-w-[50%]">
          {imageTop && imageTop.src && (
            <DeviceImage
              id={'top-right'}
              image={imageTop}
              styleOverrides={`absolute pointer-events-none right-[-40%] lg:right-[-10%] flex justify-end object-contain top-0
                              ${imageMobile?.src ? 'max-sm:right-[85%]' : 'max-sm:right-[25%]'}  z-10 sm:h-[290px] lg:h-[516px] 
                  h-[500px] w-[100%] md:w-[70%] md:top-[-10px] md:h-[465px]  lg:top-[150px] lg:h-[650px] lg:w-full
              `}
              configOverrides={{
                mobile: { attribute: 'x', values: [250, 0] },
                desktop: { attribute: 'y', values: [-75, 75] },
              }}
              windowSize={windowSize}
            />
          )}
        </div>
      </motion.div>
    </motion.section>
  );
};
