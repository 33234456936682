"use client";

import { useCallback } from 'react';
import { useTranslation as useT } from 'react-i18next';
//import { sanitize } from 'isomorphic-dompurify';

// import { JSDOM } from 'jsdom'
// import DOMPurify from 'dompurify'
// const { window } = new JSDOM('<!DOCTYPE html>')
// const { sanitize } = DOMPurify(window)


const joinLastThreeWords = (text) => {
  return text;
  
  // This function applies non-breaking spaces between the last three words across plain text and HTML content.
  if (text.split(/<[^>]*>|[^<>\s]+/).filter(Boolean).length <= 6) {
    return text;
  }

  return text.replace(/(?:<[^>]*>|[^<>\s]+|\s(?=\s*<))+$|[^<>\s]+$/g, (m, i, s) => {
    // Replace spaces between last three words with &nbsp;
    if (/<|>/.test(m)) {
      // Handle HTML tags separately
      return m.replace(/([^<>\s]+\s+[^<>\s]+\s+[^<>\s]+)$/, (match) => match.replace(/\s/g, '&nbsp;'));
    }
    return i + m.length === s.length ? m.replace(/(\S+)\s+(\S+)\s+(\S+)$/, '$1&nbsp;$2&nbsp;$3') : m;
  });
};

const sanitize = text => {
  return text.replace(/<\s*\/{0,1}\s*(script|embed|iframe)\s*>{0,1}/gm, '')
}


export const useSanitizedTranslation = () => {
  const { t: translation } = useT('common');
  const t = useCallback(
    (key: string) => {
      const text = translation(key);

      return text; //sanitize(text);
    },
    [translation],
  );

  return { t };
};
