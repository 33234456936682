/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import { CTA, IImage } from '../types';
import { MotionImage } from './AnimatedImage';
import { Button } from '.';
import { makeVariants } from '../utils/makeVariants';
import { useScrolledTheme, useInViewScroll, SplitH2, useSanitizedTranslation } from 'ui';
import Head from 'next/head';
import { BazaarVoiceBlock } from './BazaarVoiceBlock';
import Image from 'next/image';
import devicesJSON from 'ui/devices.json'


const DeviceOpenGraph = ({heading, description, images, url}) => {
  const getGalleryJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      '@id': 'https://www.xumo.com' + url,
      brand: 'Xumo',
      name: heading,
      description: description,
      image: images.src,
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getGalleryJsonLd()} />
      </Head>
    </>
  )
}

export type ProductDetailBlockProps = {
  deviceId: string;
};

// TODO: add more props
//const ShopButton: React.FC<{ image: IImage }> = ({ image }) => {
const ShopButton = ({ image, url, name, label, bvValue }) => {
  return (
    <div className="inline-block">
      <Button
        label={label}
        href={url}
        disabled={!url}
        buttonType={!url ? "submit-primary" : "link-primary"}
        onClick={evt =>{
          evt.preventDefault();

          BV && BV.pixel.trackConversion({
            type: 'Shop now',
            label: name,
            value: bvValue,
          });

          window.open(evt.currentTarget.href, '_blank');
        }}
        
        styleOverrides={{
          tailwind: 
            'inline-block px-6 bg-xumoBerry disabled:bg-xumoSmoke cursor-pointer disabled:cursor-default disabled:text-xumoGrey disabled:drop-shadow-none',
          css: {
            backgroundColor: '',
            color: 'white',
          },
        }}
      />
    </div>
  );
};

export const ProductDetailBlock: React.FC<ProductDetailBlockProps> = ({deviceId = 0, size}) => {

  const { t } = useSanitizedTranslation()

  const { 
    productId = '001', 
    features, 
    buttons, 
    sizes, 
    heading: h1,
    brand, 
    logo,
    images,
    redirectUrl
  } = devicesJSON[deviceId]

  const heading = h1.replace('$size', size + '"')

  return <>
    <DeviceOpenGraph heading={heading} description={features.join(', ')} images={images} url={redirectUrl} />
    <motion.section className={'wrapper'} data-test-id="product-detail-block" style={{color: '#000'}}>
      <div className="content-wrapper">
        <div className="text-left">
          <div className="flex flex-col-reverse md:flex-row">
            <div className="relative w-full md:w-3/5 bg-white p-4 rounded-xl"> 
              <div className="relative aspect-video"> 
                <Image 
                  fill
                  alt={"Product image of " + heading}
                  className="object-contain"
                  {...images} />
              </div>
              <div className="relative h-[30px] align-center flex space-x-4 justify-center mt-4">
                <Image 
                  alt="Works with Apple AirPlay" 
                  src={"/static/images/works-with-airplay.svg"} 
                  height={30 * 4/3} 
                  width={111 * 4/3} 
                  sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" 
                  className="object-left object-cover" />
                <Image 
                  alt="Works with Apple Home"
                  src={"/static/images/works-with-applehome.svg"} 
                  height={30 * 4/3} 
                  width={105 * 4/3} 
                  sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" 
                  className="object-left object-cover" />
              </div>
            </div>
            <div className="md:pl-8 space-y-4 mb-4">
              <div className="relative w-full"  style={{height: 30, marginBottom: 10}}>
                <Image 
                  alt={brand + ' logo'} 
                  src={logo} sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" fill className="object-left object-contain" />
              </div>
              <h1 className="type-subheading-small">{t(heading)}</h1>
              <div
                data-bv-show="rating_summary"
                data-bv-product-id={productId} />
              <ul className="list-disc pl-4">
                {features.map(feature => <li key={feature} className="type-body-base" data-i18n>{t(feature)}</li>)}
              </ul>
              <ShopButton {...buttons[0]} />
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  </>;
};