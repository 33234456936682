/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useState } from 'react';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import { CTA, IImage } from '../types';
import { useContent } from '../hooks';
import MotionImage from './AnimatedImage';
import { Button } from '.';
import { makeVariants } from '../utils/makeVariants';
import { useScrolledTheme, useInViewScroll, SplitH2, useSanitizedTranslation } from 'ui';
import Head from 'next/head';
import { BazaarVoiceBlock } from './BazaarVoiceBlock';
import Image from 'next/image';
import Link from 'next/link';

const squiggleVariant = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};

export type DeviceListBlockProps = {
  heading: string;
  body: string;
  images: { image: IImage; label: CTA['label']; id: string }[];
  cta: CTA;
  accent: string;
  backgroundColor: string;
};

// TODO: add more props
//const ShopButton: React.FC<{ image: IImage }> = ({ image }) => {
const ShopButton = ({ image, url, name, label, bvValue }) => {

  return (
    <>
      <Button
        label={label}
        href={url}
        disabled={!url}
        buttonType={!url ? "submit-primary" : "link-primary"}
        onClick={evt =>{
          evt.preventDefault();
          window.open(evt.currentTarget.href, '_blank');
        }}
        styleOverrides={{
          tailwind: 'px-6 bg-xumoBerry disabled:bg-xumoSmoke cursor-pointer disabled:cursor-default disabled:text-xumoGrey disabled:drop-shadow-none',
          css: {
            backgroundColor: '',
            color: 'white',
          },
        }}
      />
    </>
  );
};

const DeviceItem: React.FC<{
  strap: string;
  heading: string;
  body: string;
  sizes: any;
  features: any;
  images: IImage[];
  buttons: any[];
}> = ({ strap, brand, buttons, heading, body, sizes, features, images, logo, padLogo, moreInfo, flex, comingSoon }) => {

  const [ showInfo, setShowInfo ] = useState(false)

  const getGalleryJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      '@id': 'https://www.xumo.com/products/xumo-tv',
      brand: 'Xumo',
      name: heading,
      description: body,
      image: images.src,
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  const variants = makeVariants.slideIn();


  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getGalleryJsonLd()} />
      </Head>
      <div className={`z-20 bg-white text-black rounded-xl p-6`}>
        <div className="flex flex-col">
          <div className="relative mb-6 flex grow basis-1/2">
            <div className="relative w-full aspect-video">
              <MotionImage {...images} sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" fill className="object-contain" />
            </div>
          </div>
          <div className="flex justify-center">
            <div className="relative"  style={{height: 30, width: 125, marginBottom: 5}}>
              <MotionImage src={logo} sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" fill className="object-left object-contain" />
            </div>
            {/*<div
              data-bv-show="rating_summary"
              data-bv-product-id="001">
            </div>*/}
          </div>
        </div>
        <div className="mt-6 flex flex-col justify-center items-center space-x-0 space-y-3">
          {buttons.map(button => (
            <ShopButton {...button} />
          ))}
        </div>
      </div>
    </>
  );
};

export const DeviceListBlock: React.FC<DeviceListBlockProps> = ({ 
  heading: hhh, body: bbb, images, cta, accent, backgroundColor }) => {

  const [selected, setSelected] = React.useState<DeviceListBlockProps['images'][0]>(images[0]);


  const { t } = useSanitizedTranslation();

  const heading = "Find your new Xumo TV";
  const body = "Shop deals from your favorite retailers."

  let content = useContent({ heading, body }, [heading, body]);

  const variants = makeVariants.slideIn();

  const slideInAndOut = useMemo(
    () =>
      makeVariants.slideIn({
        from: { x: 0, y: 100 },
        animate: { transition: { duration: 0.2, delay: 0.15 } },
        moreVariants: { exit: { opacity: 0, x: 0, y: -100, transition: { duration: 0.1 } } },
      }),
    [],
  );



  const Heading = SplitH2;

  const { ref, progress } = useInViewScroll([0, 1], ['start 50vh', 'end 50vh']);
  const movement = useSpring(progress, { stiffness: 400, damping: 90 });
  const y = useTransform(progress, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(progress, [0, 0.4, 0.6, 1], [0.05, 1, 1, 0.05]);

  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#000000',
    accent,
    text: '#ffffff',
  });

  const logosVariant = { grow: { scale: 1, opacity: 1}, initial: { opacity: 0.5, scale: 0.8 }}
  const wrapperVariant = { grow: { opacity: 0.7 }, initial: { opacity: 1 }}
  
  return <>
    <div
      className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-0 h-[120vh] overflow-hidden"
    >
      <motion.div style={{ y }} className="relative w-full h-full">
        <AnimatePresence>
          {componentInView && (
            <MotionImage
              key="squiggle"
              initial="hidden"
              animate="show"
              exit="hidden"
              fill
              quality={90}
              variants={squiggleVariant}
              src={'/static/images/gradient-bg.png'}
              sizes="100vw"
              className="relative h-full w-full object-cover object-center"
            />
          )}
        </AnimatePresence>
      </motion.div>
    </div>
    <motion.section ref={ref} className={'wrapper py-16'} data-test-id="device-list-block" style={{color: colors.text}}>
      <Heading variants={variants} content={heading} className={`type-heading z-10 flex flex-col text-inherit text-center relative`} />
      {body && <motion.div variants={variants} className="type-body z-10 relative py-4 text-inherit text-center" data-i18n dangerouslySetInnerHTML={{ __html: t(body) }} />}
      <div className="flex justify-center align-center mt-8 mb-16 relative z-10 space-x-16 md:space-x-32">
        <motion.div variants={wrapperVariant} whileHover='grow' initial="initial" className="relative flex items-center">
          <Link target="_blank" href="https://www.bestbuy.com/site/searchpage.jsp?_dyncharset=UTF-8&browsedCategory=abcat0101001&id=pcat17071&iht=n&ks=960&list=y&qp=smartplatform_facet%3DSmart%20Platform~Xumo%20TV&sc=Global&st=categoryid%24abcat0101001&type=page&usc=All%20Categories">
            <Image src={'/static/images/Best_Buy_logo.svg'} width={100} height={75} />
          </Link>

          <motion.svg variants={logosVariant} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 -right-10 absolute">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </motion.svg>

        </motion.div>

       <motion.div  variants={wrapperVariant} whileHover='grow' initial="initial" className="relative flex items-center">
          <Link target="_blank" href="https://www.meijer.com/shopping/c/collections/element-xumo.html?icid=Redirect%3AXum&sort_order=relevance-descending">
            <Image src={'/static/images/MeijerLogo.svg'} width={125} height={100}/>
          </Link>

          <motion.svg variants={logosVariant} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 -right-10 absolute">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </motion.svg>
        </motion.div>

        <motion.div  variants={wrapperVariant} whileHover='grow' initial="initial" className="relative flex items-center">
          <Link target="_blank" href="https://www.walmart.com/browse/electronics/shop-tvs-by-size/3944_1060825_2489948?povid=ETS_TAVC_TVCP_Hubspoke_TVsbysize&facet=smart_tv_platform%3AXumo">
            <Image src={'/static/images/Walmart_logo.svg'} width={175} height={100} />
          </Link>

          <motion.svg variants={logosVariant} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 -right-10 absolute">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </motion.svg>
        </motion.div>

        {/* <motion.div  variants={wrapperVariant} whileHover='grow' initial="initial" className="relative flex items-center">
          <Link target="_blank" href="https://www.meijer.com/shopping/c/collections/element-xumo.html?icid=Redirect%3AXum&sort_order=relevance-descending">
            <Image src={'/static/images/Walmart_logo.svg'} width={175} height={100} />
          </Link>

          <motion.svg variants={logosVariant} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 -right-10 absolute">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </motion.svg>
        </motion.div> */}



        
       
        
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">     
        <DeviceItem
          flex
          strap=""
          heading={'4K UHD Pioneer Smart TV'}
          logo={'/static/images/PioneerLogo.svg'}
          padLogo
          sizes='65" | 55" | 50" | 43"'
          features={["4K UHD", "Dolby Vision/HDR10 compatibility", "Apple AirPlay", "300+ free streaming channels with Xumo Play"]}
          images={{
            src: '/static/images/pioneer-tv.png',
            alt: 'Image of Pioneer TV',
          }}
          buttons={[{
            image: '/BestBuy_logo.svg',
            url: 'https://www.bestbuy.com/site/searchpage.jsp?_dyncharset=UTF-8&browsedCategory=abcat0101001&id=pcat17071&iht=n&ks=960&list=y&qp=brand_facet%3DBrand~Pioneer%5Esmartplatform_facet%3DSmart%20Platform~Xumo%20TV&sc=Global&st=categoryid%24abcat0101001&type=page&usc=All%20Categories',
            name: '4K UHD Pioneer Xumo TV',
            label: 'Shop now',
            bvValue: null
          }]}
        />
        <DeviceItem
          strap=""
          body=""
          flex
          heading={'4K Element UHD Xumo TV'}
          logo={'/static/images/ElementLogo.png'}
          sizes='65" | 55" | 50" | 43"'
          features={["4K UHD", "Dolby Vision/HDR10 compatibility", "Apple AirPlay", "300+ free streaming channels with Xumo Play"]}
          images={{
            src: '/static/images/element-tv.png',
            alt: 'Image of 4K UHD Element Xumo TV',
          }}
          buttons={[{
            image: '/Meijer_logo.svg',
            url: 'https://elementelectronics.com/tv/smart-platforms-xumo-tv',
            name: '4K UHD Element Xumo TV',
            label: 'Shop now',
            bvValue: '001'
          }]}
          comingSoon
          moreInfo={{
            why: ["Impressive picture and sound quality, at a competitive price point", "Seamless platform integration", "Easy to access content for any audience"],
          }}
        />
        <DeviceItem
          strap=""
          flex
          heading={'4K UHD Hisense Smart TV'}
          logo={'/static/images/HisenseLogo.svg'}
          padLogo
          sizes='65" | 55"'
          features={["4K UHD", "Dolby Vision/HDR10 compatibility", "Apple AirPlay", "300+ free streaming channels with Xumo Play"]}
          images={{
            src: '/static/images/hisense-tv.png',
            alt: 'Image of Hisense TV',
          }}
          buttons={[{
            image: '/BestBuy_logo.svg',
            url: 'https://www.hisense-usa.com/televisions/55-a6hx-series-hisense-xumo-tv',
            name: '4K UHD Hisense Xumo TV',
            label: 'Shop now',
            bvValue: null
          }]}

          moreInfo={{
            why: ["Beautiful, sleek TV frame", "Enhanced color, contrast, and refresh rate", "User-friendly interface with ever updating content"],
          }}
        />
        
      </div>
      <Button
        label={'Shop all TVs'}
        href={'/products/xumo-tv/search'}
        buttonType={"link-primary"}
        styleOverrides={{
          tailwind: 'sm:inline-block mt-6 px-6 bg-xumoBerry disabled:bg-xumoSmoke cursor-pointer disabled:cursor-default disabled:text-xumoGrey disabled:drop-shadow-none inline-block',
          css: {
            backgroundColor: '',
            color: 'white',
          },
        }}
      />
    </motion.section>
  </>;
};
