import React from 'react';
import Image from 'next/image';
import { IImage } from '../types';
import { motion } from 'framer-motion';
import { makeVariants } from '../utils/makeVariants';
import { useSanitizedTranslation } from '../hooks';

export type ChannelBlockProps = {
  backgroundColor: string;
  accent: string;
  header: string;
  headerLogo: IImage;
  channels: Channel[];
};

type Channel = {
  header: string;
  body: string;
};

const variants = makeVariants.slideIn({
  animate: {
    transition: { staggerChildren: 0.025, delayChildren: 0.3 },
  },
});

export const ChannelBlock = ({ backgroundColor, accent, header, headerLogo, channels }: ChannelBlockProps) => {
  const { t } = useSanitizedTranslation();

  return (
    <section
      className={`container mx-auto px-8`}
      style={{
        backgroundColor,
      }}
      data-test-id="channel-block"
    >
      <motion.div variants={variants} initial="initial" animate="animate" className="flex flex-row flex-wrap items-center justify-start pt-8">
        <h2 className="type-heading mr-2 pt-2" data-i18n={header} dangerouslySetInnerHTML={{ __html: t(header) }} />
        <Image src={headerLogo.src} style={{ paddingTop: '0.5rem' }} width={154} height={22} alt={headerLogo.alt ?? ''} />
      </motion.div>
      <motion.ul variants={variants} initial="initial" whileInView="animate" className="mt-16 flex flex-col items-start">
        {channels.map(({ body, header }, index) => (
          <motion.li variants={variants} key={index} className="mb-4 flex w-full flex-col items-start border-b border-b-black pb-4 ">
            <h3 className="type-heading" style={{ color: accent }} data-i18n={header} dangerouslySetInnerHTML={{ __html: t(header) }} />
            <p className="type-body" data-i18n={body} dangerouslySetInnerHTML={{ __html: t(body) }} />
          </motion.li>
        ))}
      </motion.ul>
    </section>
  );
};
