/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useSanitizedTranslation } from 'ui';
import { useMeasure } from '@uidotdev/usehooks'


const FAQItem = ({ item, setOpen, index, isOpen }) => {
    
  const { t } = useSanitizedTranslation();
  const [ contentRef, { height: contentHeight } ] = useMeasure();

  const toggleOpen = (evt) => {
      setOpen(val => index === val ? -1 : index)
  }

  const motionProps = isOpen
      ? {
          display: 'block',
          height: contentHeight
      }
      : {
          height: 0,
          transitionEnd: {
              display: 'none'
          }
      }

  return <div className="type-body border-b border-black w-full">
      <button aria-label={isOpen ? "Collapse" : "Expand"} id={'faq' + index} className="flex py-8 text-left text-[20px] weight-500 w-full justify-between" onClick={toggleOpen}>
         <div>{t(item.question)}</div>
         <div className="p-2 -mr-2"><img src="/static/icons/chevron.svg" className="transition-transform" alt="" style={{width: 20, height: 20, transform: isOpen ? 'scaleY(1)' : 'scaleY(-1)'}} /></div>
      </button>
      <motion.div 
          className="overflow-clip relative"
          animate={motionProps}>
          <div ref={contentRef} className="pb-8 allow-html text-left" dangerouslySetInnerHTML={{ __html: item.answer }} />
          <div className="absolute left-0 bottom-0 right-0 h-8" style={{background: 'linear-gradient(to bottom, #fff0, #ffff)'}}></div>
      </motion.div>
  </div>
};


export const FaqBlock = ({ heading, bg, questions }) => {
  const [ open, setOpen ] = useState(-1);
  //const faq = faqsXumoTV;

  const { t } = useSanitizedTranslation();
  return <motion.section className="relative z-40 bg-white w-full flex items-center justify-centent" data-testid={'text-section'}>
    <div className="wrapper">
      <div className={`content-wrapper flex justify-center items-center flex-col ${bg}`}>
        <div className="w-3/4 text-center mb-8">
          <h2 class="type-heading">{heading}</h2>
        </div>
        <div className="max-w-[900px] w-full">
        {questions.map((item, index) => <FAQItem key={"faq-item-"+index} item={item} setOpen={setOpen} index={index} isOpen={open === index} />)}
        </div>
      </div>
    </div>
  </motion.section>
}