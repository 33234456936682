import { DirectusVideoHero } from 'config';
import { VideoHeroProps } from '../../../components';
import { mapDirectusCTAs, mapDirectusImage } from '../general';

export const mapDirectusVideoHero = ({ cta_items, heading, body, image, ...rest }: DirectusVideoHero): VideoHeroProps => {
  return {
    heading,
    body,
    ctas: mapDirectusCTAs(cta_items ?? [], undefined, true),
    image: mapDirectusImage(image),
  };
};
