/* eslint-disable max-len */

import { mapDirectusMetadata, mapDirectusPage } from './mapping';

import { DirectusPage } from 'config';
import websiteContent from 'config/xumo-website-output.json';
import { GetGeneralPageStaticPropsArgs, GetGeneralPageStaticPropsContext, GetGeneralPageStaticPropsResponse } from './getGeneralPageStaticProps';

const get404PageContent = (website: string) => {
  const data = websiteContent.content as any;
  return data.find((page: DirectusPage) => page.website === website && page.slug === '404');
};

export const get404PageStaticProps =
  ({ website }: GetGeneralPageStaticPropsArgs) =>
  async ({ locale }: GetGeneralPageStaticPropsContext): Promise<{ props: GetGeneralPageStaticPropsResponse }> => {
    const data = get404PageContent(website);
    const page = mapDirectusPage(data, websiteContent.settings);
    const metadata = mapDirectusMetadata(data);

    return {
      props: {
        // hack to get around next.js complaining about undefined values
        page: JSON.parse(JSON.stringify(page)),
        metadata,
        locale: locale ?? 'en',
      },
    };
  };
