/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence } from 'framer-motion';

import { useRef } from 'react';
import { Button, useInViewScroll, useScrolledTheme, MotionImage, useSanitizedTranslation } from 'ui';
import { SplitH1, SplitH2 } from './';
import Link from 'next/link';

const variants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.01,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.02,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

const copyVariants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.01,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 80,
      damping: 23,
    },
  },
};

export const KeyartBlock: React.FC<{ isInView: boolean; firstSrc: string; secondSrc: string; text?: string[]; streamUrl?: string }> = ({
  firstSrc,
  secondSrc,
  isInView,
  text,
}) => {
  const delay = useRef(Math.random() * 0.5);

  return (
    <motion.div
      variants={variants}
      className="keyart relative inline-block aspect-video h-[100px] shrink-0 overflow-hidden rounded-lg lg:h-[15vh]"
      style={{ borderColor: '#fff' }}
    >
      <AnimatePresence>
        {(isInView ? secondSrc : firstSrc) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: delay.current, duration: 0.4 }}
            key={delay + (isInView ? secondSrc : firstSrc)}
            className="absolute h-full w-full"
          >
            {text && !isInView && (
              <div className="absolute z-10 flex h-full w-full flex-col items-center justify-center text-white">
                <span className="type-body-bold weight-700 text-xl leading-tight xl:text-3xl 2xl:text-5xl">{text[0]}</span>
                <span className="type-body text-sm leading-tight lg:text-lg 2xl:text-xl">{text[1]}</span>
              </div>
            )}
            <MotionImage
              src={isInView ? secondSrc : firstSrc}
              fill
              priority
              alt="Keyart"
              sizes="(max-width: 768px) 50vw, (max-width: 1600px) 20vw, 15vw"
              className="top-left absolute left-0 top-0 h-full w-full object-cover"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export const Circle: React.FC<{ isInView: boolean; firstSrc: string; secondSrc: string }> = ({ firstSrc, secondSrc, isInView }) => {
  const delay = useRef(Math.random() * 0.6);

  return (
    <motion.div
      variants={variants}
      className="keyart relative inline-block aspect-square h-[100px] shrink-0 overflow-hidden rounded-full lg:h-[15vh]"
      style={{ borderColor: '#fff' }}
    >
      <AnimatePresence>
        {(isInView ? secondSrc : firstSrc) && (
          <MotionImage
            key={isInView ? secondSrc : firstSrc}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: delay.current, duration: 0.4 }}
            src={isInView ? secondSrc : firstSrc}
            fill
            priority
            alt="Logo"
            sizes="(max-width: 768px) 50vw, (max-width: 1600px) 20vw, 15vw"
            className="absolute left-0 top-0 h-full w-full object-cover"
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

/*
https://play.xumo.com/free-movies/fashionably-in-love/XM02MZEGYB5P3I
https://play.xumo.com/free-movies/the-great-debaters/XM0004ZJVDJNT0
https://play.xumo.com/live-guide/the-walking-dead-universe
https://play.xumo.com/networks/anger-management-channel/99951192

https://play.xumo.com/live-guide/supermarket-sweep
https://play.xumo.com/live-guide/are-we-there-yet
https://play.xumo.com/tv-shows/anthony-bourdain-a-cook-s-tour/XM082SH413TZU4
https://play.xumo.com/live-guide/bounce-xl

*/

const DealsBanner = () => {
  const { t } = useSanitizedTranslation();

  return <motion.div 
    initial={{opacity: 0, scale: 0.8}} 
    animate={{opacity: 1, scale: 1}} 
    transition={{ delay: 1.2, duration: 0.8, ease: 'easeOut' }}
    style={{ boxShadow: '0 0 15px rgba(255, 0, 255, 0.5), 0 0 2px rgba(255,0,255,0.6)' }} 
    className="bg-[#00000044] border-2 border-xumoBerry p-3 mb-12 rounded-lg inline-block border-bottom">
    <div>
    <span data-i18n className="text-body text-sm weight-500 mr-2 inline-block">{t('Get 6 months of Peacock Premium at no extra cost — just in time for The Olympics.')}</span>

      <Link href="/products/xumo-tv#peacock-offer">
        <span className="text-body text-sm underline text-white hover:no-underline underline-offset-[5px]">{t("Discover more")}&nbsp;&rsaquo;</span>
      </Link>
    </div>
  </motion.div>
}


export type LandingHeroProps = {};

export const LandingHero = ({}) => {
  const { t } = useSanitizedTranslation();

  const { ref, progress } = useInViewScroll([0, 1], ['start 0vh', 'end 0vh']);
  const amt = useSpring(progress, { stiffness: 400, damping: 90 });

  const containerX = useTransform(amt, [0, 1], [0, 100]);
  const container2 = useTransform(amt, [0, 1], [0, -100]);
  const containerY = useTransform(amt, [0, 1], ['0vh', '-30vh']);
  const containerY2 = useTransform(amt, [0, 1], ['0vh', '-15vh']);

  const playSection = useRef(null);
  const isInView = useInView(playSection, { margin: '-50% 0px -10% 0px' });

  const { componentInView } = useScrolledTheme({
    ref,
    background: '#000000',
    accent: '#F41A83',
    text: '#ffffff',
  });

  return (
    <>
      <motion.section ref={ref} animate={{ color: componentInView ? '#ffffff' : '#000000' }} className="border-3 w-full" data-testid={'keyart-section'}>
        <AnimatePresence>
          {componentInView && (
            <motion.div
              key="homepage-hero"
              style={{ y: containerY2 }}
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 0.15 }}
              exit={{ opacity: 0.0 }}
              className="fixed bottom-0 left-0 right-0 top-0 h-screen w-screen blur-sm"
            >
              <MotionImage src="/homescreen-bg.jpg" alt="" fill priority quality={20} sizes="100w" className="object-cover" />
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          initial="hidden"
          animate="show"
          variants={variants}
          className="wrapper relative flex w-full flex-col justify-center text-left text-white max-lg:pb-[50px] max-lg:pt-[100px] lg:h-[calc(100vh-100px)] lg:min-h-[500px]"
        >
          <div className="xl:1/2 w-full lg:w-3/5">
            <DealsBanner />
            <SplitH1
              content={'All you<br/>can stream<sup>&trade;</sup>'}
              variants={copyVariants}
              className={`type-title w-full text-5xl leading-none sm:text-7xl lg:w-4/5 xl:w-full xl:text-8xl 2xl:text-9xl`}
            />
            <motion.p
              variants={copyVariants}
              data-i18n={'Your world of entertainment &mdash; all in one place.'}
              className={`type-body w-full pt-6`}
              dangerouslySetInnerHTML={{ __html: t('Your world of entertainment &mdash; all in one place.') }}
            />
            {/*<motion.div variants={copyVariants} className="space-x-6 pt-6">
              <Button href="/shop" label={'Shop devices'} buttonType="link-primary" styleOverrides={`bg-xumoAegean inline-block`} />
            </motion.div>*/}
          </div>
        </motion.div>

        <div className="pointer-events-none relative bottom-0 left-0 right-0 h-[575px] overflow-hidden max-lg:z-10 md:h-[750px]  lg:fixed lg:top-0 lg:h-[120vh]">
          <motion.div style={{ y: containerY }} className="wrapper max-lg:transform-none">
            <AnimatePresence>
              {componentInView && (
                <motion.div
                  key="right-keyart"
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={variants}
                  className="relative left-[-10%] w-[120%] space-y-2 lg:absolute lg:left-[62%] lg:top-[125px] lg:space-y-4 xl:left-[60%] xl:top-[175px] 2xl:left-[55%]"
                >
                  <motion.div style={{ x: container2 }} className="max-md:justify-centerleft-[10%] relative flex space-x-2 lg:space-x-4">
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/xumo-movies.jpg" secondSrc="/bubbles/xumo-movies.jpg" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-dateline.jpg" secondSrc="/bubbles/channel-dateline.jpg" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/logo-ion.jpg" secondSrc="/logo-ion.jpg" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/logo-hallmark.jpg" secondSrc="/logo-hallmark.jpg" />
                    <Circle
                      isInView={isInView || !componentInView}
                      firstSrc="/bubbles/channel-XumoFreeDocumentaries.jpg"
                      secondSrc="/bubbles/channel-XumoFreeDocumentaries.jpg"
                    />
                    <Circle
                      isInView={isInView || !componentInView}
                      firstSrc="/bubbles/channel-XumoFreeNature&WildlifeTV.jpg"
                      secondSrc="/bubbles/channel-XumoFreeNature&WildlifeTV.jpg"
                    />
                    <Circle
                      isInView={isInView || !componentInView}
                      firstSrc="/bubbles/channel-XumoFreeActionMovies.jpg"
                      secondSrc="/bubbles/channel-XumoFreeActionMovies.jpg"
                    />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/xumo-movies.jpg" secondSrc="/bubbles/xumo-movies.jpg" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/bubbles/channel-dateline.jpg" secondSrc="/bubbles/channel-dateline.jpg" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/logo-ion.jpg" secondSrc="/logo-ion.jpg" />
                    <Circle isInView={isInView || !componentInView} firstSrc="/logo-hallmark.jpg" secondSrc="/logo-hallmark.jpg" />
                    <Circle
                      isInView={isInView || !componentInView}
                      firstSrc="/bubbles/channel-XumoFreeDocumentaries.jpg"
                      secondSrc="/bubbles/channel-XumoFreeDocumentaries.jpg"
                    />
                    <Circle
                      isInView={isInView || !componentInView}
                      firstSrc="/bubbles/channel-XumoFreeNature&WildlifeTV.jpg"
                      secondSrc="/bubbles/channel-XumoFreeNature&WildlifeTV.jpg"
                    />
                    <Circle
                      isInView={isInView || !componentInView}
                      firstSrc="/bubbles/channel-XumoFreeActionMovies.jpg"
                      secondSrc="/bubbles/channel-XumoFreeActionMovies.jpg"
                    />
                  </motion.div>
                  <motion.div style={{ x: containerX }} className="relative left-[0%] flex space-x-2 max-md:justify-center lg:space-x-4">
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/swirl-tile-1.png" secondSrc="/keyart-1.webp" text={['100+', 'apps']} />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/swirl-tile-2.png"
                      secondSrc="/keyart/keyart-debaters.jpg"
                      text={['300+', 'free channels*']}
                      streamUrl="https://play.xumo.com/free-movies/the-great-debaters/XM0004ZJVDJNT0"
                    />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/swirl-tile-3.png"
                      secondSrc="/keyart/keyart-walking-dead.webp"
                      text={['10k+', 'free movies*']}
                    />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/swirl-tile-4.png"
                      secondSrc="/keyart/keyart-anger-mangagement.webp"
                      text={['250+', 'TV shows']}
                    />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/swirl-tile-1.png"
                      secondSrc="/keyart-1.webp"
                      text={['1 billion+', 'hours streamed']}
                    />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/swirl-tile-2.png"
                      secondSrc="/keyart/keyart-debaters.jpg"
                      text={['10k+', 'free movies*']}
                    />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/swirl-tile-3.png"
                      secondSrc="/keyart/keyart-walking-dead.webp"
                      text={['100+', 'apps']}
                    />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/swirl-tile-4.png"
                      secondSrc="/keyart/keyart-anger-mangagement.webp"
                      text={['300+', 'free channels*']}
                    />
                  </motion.div>
                  <motion.div style={{ x: container2 }} className="relative left-[10%] flex space-x-2 max-md:justify-center lg:space-x-4">
                                      <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/keyart/keyart-netflix.jpg"
                      secondSrc="/keyart/keyart-are-we-there-yet.webp"
                    />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-max.webp" secondSrc="/keyart/keyart-supermarket.jpg" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-black.png" secondSrc="/keyart/keyart-anthony.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/Shwotime.png" secondSrc="/keyart/keyart-bounce.webp" />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/keyart/keyart-netflix.jpg"
                      secondSrc="/keyart/keyart-are-we-there-yet.webp"
                    />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-max.webp" secondSrc="/keyart/keyart-supermarket.jpg" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-black.png" secondSrc="/keyart/keyart-anthony.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/Shwotime.png" secondSrc="/keyart/keyart-bounce.webp" />
                  </motion.div>
                  <motion.div style={{ x: containerX }} className="relative left-[0%] flex space-x-2  max-md:justify-center lg:space-x-4">
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/xumo-play-tile.png" secondSrc="/keyart/keyart-bigfish.jpg" />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/keyart/keyart-appletv-black.jpg"
                      secondSrc="/keyart/keyart-filmrise-heartland.webp"
                    />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-disney.jpg" secondSrc="/keyart/keyart-whose-line.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/Paramount.png" secondSrc="/keyart/keyart-xumo-action.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/xumo-play-tile.png" secondSrc="/keyart/keyart-bigfish.jpg" />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/keyart/keyart-appletv-black.jpg"
                      secondSrc="/keyart/keyart-filmrise-heartland.webp"
                    />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-disney.jpg" secondSrc="/keyart/keyart-whose-line.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/Paramount.png" secondSrc="/keyart/keyart-xumo-action.webp" />
                  </motion.div>
                  <motion.div style={{ x: container2 }} className="relative left-[10%] flex space-x-2  max-md:justify-center lg:space-x-4">
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-youtube.jpg" secondSrc="/keyart/keyart-punky-brewster.webp" />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/keyart/keyart-primevideo.jpg"
                      secondSrc="/keyart/keyart-hells-kitchen.webp"
                    />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-hulu.jpg" secondSrc="/keyart/keyart-xumo-crime-2.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/Sling.png" secondSrc="/keyart/xumo-gameshow.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-youtube.jpg" secondSrc="/keyart/keyart-punky-brewster.webp" />
                    <KeyartBlock
                      isInView={isInView || !componentInView}
                      firstSrc="/keyart/keyart-primevideo.jpg"
                      secondSrc="/keyart/keyart-hells-kitchen.webp"
                    />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-hulu.jpg" secondSrc="/keyart/keyart-xumo-crime-2.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/Sling.png" secondSrc="/keyart/xumo-gameshow.webp" />
                  </motion.div>
                  <motion.div style={{ x: containerX }} className="relative left-[0%] flex space-x-2 max-lg:hidden  max-md:justify-center lg:space-x-4">
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-peacock.jpg" secondSrc="/keyart/keyart-ufc.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-amc.jpg" secondSrc="/keyart/keyart-dick-van-dyke.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-vudu.jpg" secondSrc="/keyart/keyart-antiques.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/BET.png" secondSrc="/keyart/keyart-degrassi.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-peacock.jpg" secondSrc="/keyart/keyart-ufc.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-amc.jpg" secondSrc="/keyart/keyart-dick-van-dyke.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/keyart-vudu.jpg" secondSrc="/keyart/keyart-antiques.webp" />
                    <KeyartBlock isInView={isInView || !componentInView} firstSrc="/keyart/BET.png" secondSrc="/keyart/keyart-degrassi.webp" />
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>

        <div ref={playSection} className=" wrapper relative w-full pb-[50px] text-left max-lg:-mt-[150px] max-lg:pt-[150px] lg:pb-[300px]">
          <div className="xl:1/2 w-full lg:w-3/5">
            <motion.div variants={variants} initial="hidden" whileInView="show" viewport={{ once: true, margin: '-90% 0% -10% 0%' }}>
              <SplitH2 content={'Discover<br/>freely'} variants={copyVariants} className={`type-heading w-full leading-none lg:w-1/2`} />
              <motion.p variants={copyVariants} data-i18n className={`type-body w-full lg:w-4/5 pt-6 `}>
                {t(
                  'Spend less time searching, more time streaming across our line of easy-to-use, easy-to-love TVs and stream boxes, or watch hit series on your favorite device with Xumo Play.',
                )}
              </motion.p>
              <motion.div variants={copyVariants} className="flex-col space-y-3 pt-6 md:flex-row md:space-x-6">
                {/*<Button href="/products/xumo-tv" label={'Shop devices'} buttonType="link-primary" styleOverrides={`bg-xumoAegean inline-block`} />*/}
                <Button href="https://play.xumo.com" label={'Stream now'} buttonType="link-primary" styleOverrides={`bg-xumoAegean sm:inline-block`} />
              </motion.div>
            </motion.div>
          </div>
        </div>
        {/*@ts-ignore */}
        <style jsx global>{`
          body {
            background: #000;
            transition: background 0.5s;
          }
        `}</style>
      </motion.section>
    </>
  );
};
