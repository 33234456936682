import { DirectusFloatingButton } from 'config';
import { FloatingButtonProps } from '../../../components';
import { mapDirectusCTAs } from '../general';

export const mapDirectusFloatingButton = ({ cta_items, right, bottom }: DirectusFloatingButton): FloatingButtonProps => {
  return {
    cta: mapDirectusCTAs(cta_items ?? [], undefined, true),
    right: right,
    bottom: bottom,
  };
};
