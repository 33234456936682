'use client';

/* eslint-disable max-len */
import { motion, useSpring, useTransform, AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useInViewScroll, useSanitizedTranslation, useWindowSize } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';
import { CopyProps, CopySection } from './CopySection';
import { useScrolledTheme, GlobeBlock, StatsBlock, TechStackBlock, SplitH1, Button } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';

import { CTA, IImage } from '../types';

export type TvSplashBlockProps = {
  copyProps: CopyProps;
  backgroundColor: string;
  imageLayout: { top: Layout; bottom: Layout };
  topSwirl: IImage;
  bottomSwirl?: IImage;
  deviceImages?: IImage[];
  devicePlacement?: 'left-overlap' | 'left' | 'right-overlap' | 'right';
  ctas: CTA[];
  headingOne?: boolean;
  fineprint?: string;
};

type Layout = 'full-screen' | 'right' | 'right-wide';


const DealsBanner = () => {
  const { t } = useSanitizedTranslation();

  return <motion.div 
    initial={{opacity: 0, scale: 0.8}} 
    animate={{opacity: 1, scale: 1}} 
    transition={{ delay: 1.2, duration: 0.8, ease: 'easeOut' }}
    style={{ boxShadow: '0 0 15px rgba(255, 0, 255, 0.5), 0 0 2px rgba(255,0,255,0.6)' }} 
    className="bg-[#00000044] border-2 border-xumoBerry p-3 mb-12 rounded-lg inline-block border-bottom">
    <div>
    <span data-i18n className="text-body text-sm weight-500 mr-2 inline-block">{t('Get 6 months of Peacock Premium at no extra cost — just in time for The Olympics.')}</span>

      <Link href="#peacock-offer">
        <span className="text-body text-sm underline text-white hover:no-underline underline-offset-[5px]">{t("Discover more")} &rsaquo;</span>
      </Link>
    </div>
  </motion.div>
}

const squiggleVariant = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

// File uploads are broken at the moment for Directus (May 5)
const fallbacks = {
  '/shop/xumo-tv': '/belair-tv.webp',
  '/shop/xumo-stream-box': '/stream-box.webp',
};
const swirlFallback = {
  '/shop/xumo-tv': '/new-swirl-1.webp',
  '/shop/xumo-stream-box': '/orange-swirl.webp',
  '/shop/streaming': '/pink-swirl.webp',
};

// TODO: Add accents to TvSplash
const colorFallback = {
  '/shop': '#396059',
  '/shop/xumo-tv': '#0029FF',
  '/shop/xumo-stream-box': '#0029FF',
  '/shop/streaming': '#0029FF',
};

const copyVariants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.01,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 80,
      damping: 23,
    },
  },
};

export const FullbleedHeroAlt: React.FC<TvSplashBlockProps> = ({
  copyProps,
  backgroundColor = '#ffffff', 
  bgImage,
  imageLayout,
  headingOne = true,
  fineprint,
  imageFineprint,
  ctas
}) => {

  const { width } = useWindowSize();
  const { ref, progress: y } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const amt = useSpring(y, { stiffness: 400, damping: 90 });
  const containerY = useTransform(amt, [0, 1], ['0', '-15vh']);

  const router = useRouter();
  const fallbackImage = fallbacks[router.asPath as keyof typeof fallbacks];
  const fallbackSwirl = '/static/images/streambox-hero.png';
  const fallbackColor = colorFallback[router.asPath as keyof typeof colorFallback];

  const variants = makeVariants.slideIn();
  const imgVariants = {
    initial: {
      opacity: 0,
      rotateY: '-50deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        delay: 0.4 * i,
      },
    }),
  };

  const videoVariants = {
    initial: {
      opacity: 0,
      rotateY: '-60deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 30,
      },
    }),
  };

  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent: fallbackColor || '#2AAC00',
    text: '#000000',
  });

  return (
    <>
      <div className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 h-[115vh] overflow-hidden">
        <motion.div style={{ y: containerY }} className="relative h-full w-full ">
          <AnimatePresence>
            {fallbackSwirl && componentInView && (
              <motion.div 
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={squiggleVariant} 
                key="video-hero" className="relative h-full w-full">
                <div className="absolute w-full h-full z-10" 
                  style={{ background: 'linear-gradient(to right, #155772dd 10%, #15577200 100%)'}} />
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  src="/static/videos/streambox-loop.mp4"
                  alt=""
                  className="relative h-full w-full object-cover object-top"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      <section ref={ref} className={`relative flex w-full flex-col pb-[50px] max-md:mt-[100px] md:flex-row z-10`} data-id={'tv-splash-block'}>
        <motion.div
          variants={variants}
          initial="initial"
          whileInView={'animate'}
          viewport={{ once: true }}
          className={`wrapper relative z-[2] flex flex-col items-center justify-center md:flex-row `}
          style={{ color: colors.text }}
        >
          <div className="w-full text-left">
            <motion.div
              initial="hidden"
              animate="show"
              variants={variants}
              className="wrapper relative flex w-full flex-col justify-center text-left text-white max-lg:pb-[50px] max-lg:pt-[100px] lg:h-[calc(100vh-100px)] lg:min-h-[500px]"
            >
              <div className="xl:1/2 w-full lg:w-3/5 max-md:text-center">
                <DealsBanner />
                <SplitH1
                  content={'All you<br/>can stream<sup>&trade;</sup>'}
                  variants={copyVariants}
                  className={`type-title w-full text-center lg:text-left text-5xl leading-none sm:text-7xl lg:w-4/5 xl:w-full xl:text-8xl 2xl:text-9xl`}
                />
                <motion.div variants={copyVariants} className="space-x-6 max-md:space-y-6 pt-6 flex flex-col md:flex-row items-center">
                  <Button href="#about" label={'Learn more'} buttonType="link-primary" styleOverrides={`bg-xumoBerry inline-block sm:inline-block mr-4`} />
                  <Link href="#sizzle" legacyBehavior passHref>
                    <motion.a whileHover="grow"  className={`inline-flex flex-row`}>
                      <motion.div variants={{grow: { scale: 1.1 }}} className="z-10 w-[50px] h-[50px] relative pointer-events-none">
                        <Image src="/static/icons/play_glow.svg" fill />
                      </motion.div>
                      <motion.span variants={{grow: { opacity: 0.7 }}} className="weight-600 text-left ml-2 text-white">Watch the full<br/>Xumo Stream Box reel</motion.span>
                    </motion.a>
                  </Link>
                </motion.div>
              </div>
            </motion.div>            
            {fineprint && <div className="type-body mt-8 text-[12px]" data-i18n={fineprint} dangerouslySetInnerHTML={{ __html: fineprint }} />}
          </div>
        </motion.div>
      </section>
    </>
  );
};
