import { motion } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';
import { MotionImage, useSanitizedTranslation, StackedContent } from 'ui';

export const ImageColumnsBlock = ({content, bg}) => {
    const { t } = useSanitizedTranslation();

    const columns = [
        {
            title: 'Screen mirror',
            body: "Share what’s on your Apple® device — videos, web pages or spreadsheets — to Xumo TV.",
            image: '/static/images/refresh/more-ways-1.jpg'
        },
        {
            title: 'Listen to music',
            body: 'Play music from go-to playlists on Xumo TV and other AirPlay® speakers in your space — all in sync.',
            image: 'https://xumo-cms.directus.app/assets/cc7f5ea7-147d-4038-bc70-974b929f4678'
        },
        {
            title: 'Check out photos',
            body: 'Put your memories in the spotlight by viewing your favorite pictures on Xumo TV.',
            image: '/static/images/refresh/more-ways-3.jpg'
        },
    ]

    const disclaimer = "Apple, AirPlay, iPad®, iPhone®, and Mac® are trademarks of Apple Inc., registered in the U.S. and other countries and regions. The Works with Apple Home badge is a trademark of Apple Inc, AirPlay®, Apple Home®, and HomeKit® are trademarks of Apple Inc., registered in the U.S. and other countries and Apple, AirPlay, iPad, iPhone, and Mac are trademarks of Apple Inc., registered in the U.S. and other countries and regions. The Works with Apple Home badge is a trademark of Apple Inc."

    return <motion.section className="relative z-40 bg-white w-full flex justify-center" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`content-wrapper-x flex flex-col items-center justify-center ${bg} py-16`}>
          <StackedContent
            full
            center
            noOrphans
            content={[
              { heading: '<strong>More ways</strong> to play' },
              { body: 'Use Apple AirPlay to stream movies, music, games and photos to Xumo TV, right from your iPhone, iPad or Mac.' }
            ]} />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 py-16 w-full relative flex-wrap">
            {columns.map((tile, index) => 
            <motion.div 
              initial={{opacity: 0, y: 30}} 
              whileInView={{opacity:1, y: 0}} 
              viewport={{once: true, margin: '-20% 0% -20% 0%'}}
              transition={{ ease: [0,1,1,1], duration: 1.0, delay: 0.5 + (index *.15)}}
              key={tile.image} className="relative overflow-clip space-y-4">
                <motion.div key={tile.image} className="relative overflow-clip mb-8 rounded-xl w-full aspect-[1150/762]">
                    <MotionImage
                    src={tile.image}
                    alt=""
                    priority
                    fill
                    sizes="100vw"
                    className="object-cover w-full h-full" />
                </motion.div>
                <h3 className="type-subheading-small mt-4">{tile.title}</h3>
                <p className="type-body-small">{tile.body}</p>
                
            </motion.div>)}
          </div>
          <div className="flex max-lg:flex-col justify-center max-lg:space-y-8 lg:space-x-8 mt-4">
             <MotionImage 
                alt="Works with Apple AirPlay" 
                src={"/static/images/works-with-airplay.svg"} 
                height={30*2} 
                width={111*2} 
                sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" 
                className="object-left object-contain shrink-0" />
              <MotionImage 
                alt="Works with Apple Home"
                src={"/static/images/works-with-applehome.svg"} 
                height={30*2} 
                width={105*2} 
                sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" 
                className="object-left object-contain shrink-0" />
          </div>
          <p className="type-body text-[12px] text-center mt-8">{disclaimer}</p>
        </div>
      </div>
    </motion.section>
  }
