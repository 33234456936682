'use client';

/* eslint-disable max-len */;
import React, { useRef, useState, useCallback } from 'react';
import { CTA, IImage } from '../types/common';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import { MotionImage, CTAList, Bullet, Button, IBullet, SplitH1, SplitH2, SplitH3, useInViewScroll, useWindowSize, useScrolledTheme, useSanitizedTranslation } from 'ui';
import { makeVariants } from '../utils/makeVariants';

export interface WhereToBuyProps {

}

const squiggleVariant = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};

const swirlFallback = {
  src: '/static/images/testimonials-swirl.png'
}

const testimonials = [
  '<p>"Lorem ipsum dolor sit amet consectetur adipi scing elit. Ut et massa mi liquam in hendrerit urna ellentesque"</p>',
  '<p>"Ut et massa mi liquam in hendrerit urna ellentesque. Lorem ipsum dolor sit amet consectetur adipi scing elit."</p>',
  '<p>"Whoa!"</p>'
]

export const WhereToBuy: React.FC<WhereToBuyProps> = ({

}) => {
  const [ [id, direction, random], setPage ] = useState([0,0])

  const { t } = useSanitizedTranslation();

  // TODO: cms
  
  const bgImage = swirlFallback;
  const backgroundColor = "white";
  const primary = "black";
  const heading = "Select your internet provider and start streaming"
  const body = ""

  const Heading = SplitH2;

  const { ref, progress } = useInViewScroll([0, 1], ['start 50vh', 'end 50vh']);
  const movement = useSpring(progress, { stiffness: 400, damping: 90 });
  const y = useTransform(progress, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(progress, [0, 0.4, 0.6, 1], [0.05, 1, 1, 0.05]);

  const router = useRouter();

  const variants = makeVariants.slideIn();

  const { width } = useWindowSize();
  const isMobile = width <= 719;

  const sectionRef = useRef();
  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#E0E7ED',
    accent: '#000000',
    text: '#000000',
  });

  const setTestimonial = useCallback(nid => {
    const newDirection = nid > id
      ? 1
      : -1
      
    const newId = (nid + testimonials.length) % testimonials.length
    setPage([newId, newDirection, Math.random()])
  }, [id])

  return (
    <>
      <motion.section className="w-full overflow-hidden sm:pb-0 sm:pt-0 relative z-10" data-testid={'swirl-section'}>
        <motion.div
          ref={ref}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true, margin: '-50% 0% -50% 0%' }}
          variants={variants}
          style={{color: colors.text }}
          className={`wrapper mx-auto py-16 min-h-[400px] relative flex w-full justify-center flex-col items-center`}
        >
          <Heading variants={variants} content={heading} className={`type-heading z-10 flex flex-col transition-colors`} />
          {body && <motion.div variants={variants} className="type-body py-4 lg:w-1/2 transition-colors" data-i18n dangerouslySetInnerHTML={{ __html: t(body) }} />}
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 w-full lg:w-2/3 xl:w-full mt-8 gap-8">
            <div className={`flex flex-col justify-center rounded-md bg-[#ffffff] p-8 px-16 w-full drop-shadow-xl`}>
              <div className="relative h-[75px] mb-4 flex items-center justify-center">
                <Image fill alt="Xfinity from Comcast" className="object-contain" src="/static/images/xfinity-logo.svg" />
              </div>
              <Button href="https://www.xfinity.com/learn/xumostreambox" label={'Learn more'} buttonType={"link-primary"} styleOverrides={`bg-xumoAegean inline-block sm:inline-block`} />
            </div>          
            <div className={`flex flex-col justify-center rounded-md bg-[#ffffff] p-8 px-16 w-full drop-shadow-xl`}>
              <div className="relative h-[75px] mb-4 flex items-center justify-center">
                <Image fill alt="Spectrum from Charter" className="object-contain" src="/static/images/spectrum-logo.svg" />
              </div>
              <Button href="https://www.spectrum.com/cable-tv/streaming/xumo" label={'Learn more'} buttonType="link-primary" styleOverrides={`bg-xumoAegean inline-block sm:inline-block`} />
            </div>          
            <div className={`flex flex-col justify-center rounded-md bg-[#ffffff] p-8 px-16 w-full drop-shadow-xl`}>
              <div className="relative h-[75px] mb-4 flex items-center justify-center">
                <Image fill alt="Xtream from Mediacom" className="object-contain" src="/static/images/mediacom-optimized.svg" />
              </div>
              <Button href="https://mediacomcable.com/products/xumo/" label={'Learn more'} buttonType="link-primary" styleOverrides={`bg-xumoAegean inline-block sm:inline-block`} />
            </div>  
            <div className={`flex flex-col justify-center rounded-md bg-[#ffffff] p-8 px-16 w-full`}>
              <div className="h-[75px] mb-4 type-body-bold flex items-center justify-center">
                Buy directly from Xumo
              </div>
              <Button href="https://shop.xumo.com/xumosb-xi1.html" label={'Shop now'} buttonType="link-primary" styleOverrides={`bg-xumoBerry inline-block sm:inline-block`} />
            </div>
          </div>
          <motion.div variants={variants} className="type-body-bold py-4 lg:w-1/2 mt-4" data-i18n dangerouslySetInnerHTML={{ __html: t("Interested in partnering?") }} />
          <Button href="/enterprise" label={'Xumo for Business'} buttonType="link-primary" styleOverrides={{
            tailwind: `bg-transparent text-black inline-block sm:inline-block`,
            css: { border: '2px solid #155772' }
          }} />
        </motion.div>
      </motion.section>
    </>
  );
};
