import { DirectusFooter } from 'config';
import { FooterProps } from '../../../components';
import { CTA } from '../../../types';
import { mapDirectusCTAs } from '../general';
import { mapDirectusFormFields } from '../general/formFields';

export const mapDirectusFooter = (footer: DirectusFooter): FooterProps => {
  const form = footer.subscribe_form_items?.length ? footer.subscribe_form_items[0].item : undefined;

  return {
    lightTheme: footer.lightTheme,
    contacts: (footer.contact_items ?? []).map(i => ({
      heading: i.item.heading,
      ctas: (i.item.cta_items || []).map((__, index, arr) => {
        return mapDirectusCTAs(arr, index) as CTA;
      }),
    }))[0],
    form: form
      ? {
          CTA: mapDirectusCTAs(form.cta_items) ?? { href: '', label: '' },
          field: mapDirectusFormFields(form.form_field_items)[0],
          heading: form.heading,
        }
      : undefined,
    legal: footer.legal_items.map(i => ({
      heading: i.item.heading,
      links: (i.item.cta_items ?? []).map(i => ({ label: i.item.label, href: i.item.href })),
    }))[0],
    sections: footer.footer_section_items.map(i => ({
      heading: i.item.heading,
      links: (i.item.cta_items ?? []).map(i => ({ label: i.item.label, href: i.item.href })),
    })),
  };
};
