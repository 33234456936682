import React from 'react';
import { Highlighted } from '.';
import { IImage } from '../types';
import { chunkIntoN } from '../utils/chunkIntoN';
import stripOuterHtmlTags from '../utils/stripHtmlTags';
import { DropDownBlock } from './DropDownBlock';
import { useRouter } from 'next/router';
import Link from 'next/link';
import ReactModal from 'react-modal';
import { useSanitizedTranslation } from '../hooks';

export type ChannelSearchBlockProps = {
  anchor?: string;
  heading?: string;
  genres: { heading: string; channels: string[] }[];
  searchPlaceholder?: string;
  backgroundColor: string;
  backgroundImage?: IImage;
};

export const ChannelSearchBlock: React.FC<ChannelSearchBlockProps> = ({ anchor, heading, genres, searchPlaceholder, backgroundColor, backgroundImage }) => {
  const { t } = useSanitizedTranslation();
  const router = useRouter();

  const ChannelList = ({ channels, searchValue }: { channels: string[]; searchValue: string }): JSX.Element => {
    const chunkedChannels = chunkIntoN(channels, 3);

    return (
      <ul className="my-2 flex w-full flex-col justify-between md:flex-row">
        {chunkedChannels.map((chunk, index) => (
          <li key={index}>
            <ul className="mr-2 flex flex-col text-left">
              {chunk.map(channel => {
                return (
                  <li data-i18n={channel}>
                    <Highlighted text={channel} highlight={searchValue} />
                  </li>
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    );
  };

  const anchorIsActive = anchor ? new URL('https://a.a' + router.asPath).hash === '#' + anchor : false;

  return (
    <ReactModal isOpen={anchorIsActive} style={{ overlay: { zIndex: 1000, background: 'rgba(0,0,0,.75)' }, content: { borderRadius: '1rem', border: 'none' } }}>
      <div className={'relative flex w-full flex-col'} data-test-id="channel-search-block">
    <Link aria-label="Close" href="#" scroll={false} className="weight-100 absolute right-0 p-2 z-50 outline outline-2 outline-offset-2 outline-transparent hover:outline-xumoTeal transition-colors rounded-md">
          <img src="/static/icons/ic_close_fill.svg" width={32} height={32} alt="" />
        </Link>
        <DropDownBlock
          modal
          items={genres.map(({ heading, channels }) => ({ heading, channels: channels.map(channel => t(stripOuterHtmlTags(channel))) }))}
          ItemComponent={ChannelList}
          heading={heading ?? ''}
          searchPlaceholder={searchPlaceholder ?? 'search'}
        />
      </div>
    </ReactModal>
  );
};
